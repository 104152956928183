import { minutesToMs } from '../../fcoModules/utilities';

export const StorageKey = {
    AUTH: 'auth',
    CXML_CALLBACKURL: 'callbackUrl',
    CURRENT_SHOP: 'currentShop',
    TRANSACTION_ID: 'transactionID',
    BUYER_COOKIE: 'buyerCookie',
    CURRENT_VEHICLE: 'currentVehicle',
    FEATURES: 'features',
    FEATURES_FOR_STORE: 'featuresForStore',
    HIDE_MOBILE_APP_BANNER: 'hideMobileAppBanner',
    IS_CHAT_BUTTON_HIDDEN: 'isChatButtonHidden',
    IS_COST_HIDDEN: 'isCostHidden',
    IS_EARNBACK_ELIGIBLE: 'isEarnbackEligible',
    MASQUERADE_REAL_USER: 'masqueradeRealUserAuth',
    MASQUERADE_SOURCE: 'masqueradeSource',
    PASSWORD_CHANGE_REQUIRED: 'isPasswordChangeRequired',
    PTV_LAST_STATE: 'ptvLastState',
    PUNCHOUT: 'punchout',
    STICKY_SESSION_ID: 'stickySessionId',
    TERMS_ACCEPT_REQUIRED: 'isTermsAcceptRequired',
    USER: 'user',
};

export const CacheKey = {
    COMPANY: 'company',
    CURRENT_SHOP: 'currentShop',
    WORKSHEET: 'worksheet',
};

export const cacheExpirationByKey = {
    [StorageKey.USER]: minutesToMs(15),
    [StorageKey.FEATURES]: minutesToMs(15),
    [StorageKey.FEATURES_FOR_STORE]: minutesToMs(15),
    [CacheKey.COMPANY]: minutesToMs(15),
    [CacheKey.CURRENT_SHOP]: minutesToMs(15),
    [CacheKey.WORKSHEET]: minutesToMs(5),
};

export const requestCacheTime = {};
export const getCacheRefreshKey = (key) => `${key}_refreshedOn`;

// for punchout partners (i.e. Mitchell 1) in the non-SPA app, we may need to handle things differently (i.e. use sessionStorage instead of localStorage)
// for the non-SPA, we can determine this by checking for a punchout query param from the BE
export const isPunchoutLogin = () => Boolean(new URLSearchParams(window.location.search).get(StorageKey.PUNCHOUT));
export const isPunchout = () => Boolean(isPunchoutLogin() || sessionStorage.getItem(StorageKey.PUNCHOUT));
export const trackPunchoutSession = () => sessionStorage.setItem(StorageKey.PUNCHOUT, 1);
export const getStorage = () => (isPunchout() ? sessionStorage : localStorage);
export const hasStorageItem = (storageKey) => Object.prototype.hasOwnProperty.call(getStorage(), storageKey);

export const removeStorageItem = (storageKey) => {
    getStorage().removeItem(storageKey);
    getStorage().removeItem(getCacheRefreshKey(storageKey));
};

export const getStorageItemLastRefresh = (storageKey) => {
    const cacheRefreshKey = getCacheRefreshKey(storageKey);
    if (!hasStorageItem(cacheRefreshKey)) return 0;
    return Number(getStorage().getItem(cacheRefreshKey)) || 0;
};

export const setStorageItemLastRefresh = (storageKey, refreshedOn) => {
    if (!cacheExpirationByKey[storageKey]) return;
    const cacheRefreshKey = getCacheRefreshKey(storageKey);
    getStorage().setItem(cacheRefreshKey, refreshedOn);
};

export const getStorageItemExpiration = (storageKey) => {
    const cacheExpiresInMs = cacheExpirationByKey[storageKey];
    const cacheRefreshedOn = getStorageItemLastRefresh(storageKey);
    if (!cacheExpiresInMs || !cacheRefreshedOn) return 0;
    return cacheRefreshedOn + cacheExpiresInMs;
};
export const isStorageItemExpired = (storageKey) => {
    const expiresOn = getStorageItemExpiration(storageKey);
    if (cacheExpirationByKey[storageKey] && !expiresOn) return true;
    return Boolean(expiresOn && Date.now() >= expiresOn);
};

export const getStorageItem = (storageKey) => {
    if (isStorageItemExpired(storageKey)) {
        removeStorageItem(storageKey);
        return null;
    }

    const storageItem = getStorage().getItem(storageKey);

    try {
        return JSON.parse(storageItem);
    } catch {
        return storageItem;
    }
};

export const setStorageItem = (storageKey, value) => {
    if (cacheExpirationByKey[storageKey]) {
        setStorageItemLastRefresh(storageKey, Date.now());
    } else {
        getStorage().removeItem(getCacheRefreshKey(storageKey));
    }

    getStorage().setItem(storageKey, JSON.stringify(value));
};
