var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "ValidationObserver",
    _vm._g(
      _vm._b(
        {
          ref: "validator",
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (slotScope) {
                  return [_vm._t("default", null, null, slotScope)]
                },
              },
            ],
            null,
            true
          ),
        },
        "ValidationObserver",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }