<template>
    <FcoModal @close="$emit('close')" :closeMsg="fcoM('common.close', 'Close')">
        <div slot="body">
            <p>{{ fcoM('rs.quote.newQuotePrompt', 'The quote currently has products in it, do you want to start a new quote or add to the existing quote?') }}</p>
        </div>

        <div slot="footer">
            <button type="button" class="button secondary font-weight-bold" @click="$emit('start-new-quote')">
                {{ fcoM('rs.quote.startNew', 'Start New Quote') }}
            </button>
            <button type="button" class="button font-weight-bold ml-2" @click="$emit('use-existing-quote')">
                {{ fcoM('rs.quote.addToExistingQuote', 'Add To Existing Quote') }}
            </button>
        </div>
    </FcoModal>
</template>

<script>
import { FcoModal } from 'fco/src/vue/components/modal';

export default {
    name: 'QuoteSelectionModal',
    emits: ['start-new-quote', 'use-existing-quote', 'close'],
    components: { FcoModal },
};
</script>
