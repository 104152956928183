import { Validator } from 'vee-validate';
import {
    alpha,
    digits,
    required,
    min,
    max,
    min_value as minValue,
    max_value as maxValue,
    decimal,
    email,
    numeric,
    regex,
    url,
    confirmed,
    ext,
} from 'vee-validate/dist/rules.esm';
import en from 'vee-validate/dist/locale/en';
import { localize } from '../utilities/validator';
import { Locale } from '../constants/user';

Validator.extend('alpha', alpha);
Validator.extend('digits', digits);
Validator.extend('min', min);
Validator.extend('max', max);
Validator.extend('min_value', minValue);
Validator.extend('max_value', maxValue);
Validator.extend('required', required);
Validator.extend('decimal', decimal);
Validator.extend('email', email);
Validator.extend('numeric', numeric);
Validator.extend('regex', regex);
Validator.extend('url', url);
Validator.extend('confirmed', confirmed);
Validator.extend('ext', ext);

localize(Locale.EN, en.messages);
