<template>
    <div id="infoBar" :class="['info-bar', { stuck: isStuck }]">
        <div class="content d-flex">
            <div class="info-col vs-wrap">
                <div
                    v-show="isVehicleDataChecked"
                    data-qa="vehicle-link"
                    :class="['vehicle-selector-toggle gtm-vehicle-selector-toggle', { open: isVsOpen, forced: isVsForced }]"
                    @click="toggleVehicleSelector"
                >
                    <Icon v-if="vehicle" class="d-md-none text-sm" name="check-square-o" />
                    <div>
                        <Icon class="d-block text-md" name="car" />
                    </div>
                    <strong :class="['px-2 d-none d-md-block', { 'vehicle-name': !!vehicle }]">{{ vehicleSelectorButtonText }}</strong>

                    <Icon class="d-none d-md-inline-block text-md" name="caret-down" />
                </div>
                <VehicleSelector v-show="isVehicleDataChecked && isVsOpen" ref="vehicleSelector" />
                <div v-if="!isVehicleDataChecked" class="d-flex p-2">
                    <vue-skeleton-loader type="circle" :width="30" :height="30" />
                    <vue-skeleton-loader v-if="$fcoMq.isMdUp" class="ml-1" type="rect" :width="150" :height="30" :rounded="true" />
                </div>
            </div>
            <div class="info-col search-goto flex-grow-1">
                <div class="row">
                    <div class="col flex-grow-1">
                        <ProductSearch @focus="handleSearchFocus" />
                    </div>
                    <div class="ml-2 mr-3 p-0">
                        <MiniQuote />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSkeletonLoader from 'skeleton-loader-vue';
import debounce from 'fco/src/utils/debounce';
import Icon from '@/common/components/Icon.vue';
import MiniQuote from '@/common/components/MiniQuote.vue';
import ProductSearch from '@/common/components/ProductSearch.vue';
import VehicleSelector from '@/common/components/VehicleSelector.vue';
import { isRequestIdle, isRequestPending } from '../store/request-status';

const getResizeObserverEntryHeight = (resizeObserverEntry) => {
    const { borderBoxSize, contentRect } = resizeObserverEntry;
    let height;
    if (Array.isArray(borderBoxSize)) {
        height = borderBoxSize[0].blockSize;
    } else {
        height = borderBoxSize?.blockSize ?? contentRect.height;
    }
    return height;
};

export default {
    name: 'InfoBar',
    data() {
        return {
            isStuck: false,
        };
    },
    computed: {
        ...mapState('vehicleSelector', {
            vehicleRequest: (state) => state.requests.getCurrentVehicle,
            errorLoadingData: (state) => state.errorLoadingData,
            vsHeight: (state) => state.height,
            vehicle: (state) => state.currentVehicle,
            isVsForced: (state) => !!state.forceUrl,
            isVsOpen: (state) => state.isOpen,
            isVsOpenDefault: (state) => state.isOpenDefault,
            isYearSelectorOpen: (state) => state.isYearSelectorOpen,
        }),
        isVehicleIdle() {
            return isRequestIdle(this.vehicleRequest);
        },
        isVehicleLoading() {
            return isRequestPending(this.vehicleRequest);
        },
        isVehicleDataChecked() {
            return !this.isVehicleIdle && !this.isVehicleLoading;
        },
        vehicleSelectorButtonText() {
            if (this.errorLoadingData || !this.vehicle) {
                return this.fcoM('rs.infoBar.addVehicle', 'Add Vehicle');
            }

            const { year, make, model } = this.vehicle;
            return `${year} ${make} ${model}`;
        },
    },
    components: {
        Icon,
        MiniQuote,
        ProductSearch,
        VehicleSelector,
        VueSkeletonLoader,
    },
    methods: {
        closeVehicleSelector() {
            this.$store.commit('vehicleSelector/setVsProp', { prop: 'isOpen', value: false });
        },
        handleSearchFocus() {
            if (this.$fcoMq.isSmDown) {
                this.closeVehicleSelector();
            }
        },
        openVehicleSelector() {
            this.$store.commit('vehicleSelector/setVsProp', { prop: 'isOpen', value: true });
        },
        toggleVehicleSelector() {
            if (this.isVsOpen && !this.isVsForced) {
                this.closeVehicleSelector();
            } else {
                this.openVehicleSelector();
            }
        },
        makeSticky() {
            const { $el } = this;

            const observer = new IntersectionObserver(
                ([e]) => {
                    this.isStuck = e.intersectionRatio < 1;

                    // The following VS auto-open/auto-close functionality is intended for desktop only
                    if (!this.$fcoMq.isMdUp) {
                        return;
                    }

                    if (this.isStuck) {
                        /**
                         * When scrolling, close the VS
                         * However, keep it open if the year selector is open (which means the user is probably scrolling to select a year) and VS is not in forced state
                         */
                        if (this.isVsOpen && !this.isYearSelectorOpen && !this.isVsForced) {
                            this.$store.commit('vehicleSelector/setVsProp', { prop: 'isOpen', value: false });
                        }

                        /**
                         * The user has scrolled back to the top of the page.
                         * If the VS was set to open by default on a given page, open it back up
                         */
                    } else if (!this.isVsOpen && this.isVsOpenDefault) {
                        this.$store.commit('vehicleSelector/setVsProp', { prop: 'isOpen', value: true });
                    }
                },
                { threshold: 1 }
            );

            observer.observe($el);
        },
    },
    created() {
        if (this.isVehicleIdle) this.$store.dispatch('vehicleSelector/getCurrentVehicle');
    },
    async mounted() {
        await this.$nextTick();
        this.makeSticky();

        const { $el: $infoBarEl } = this;
        const $vehicleSelectorEl = this.$refs.vehicleSelector.$el;

        // Keep track of the InfoBar height in the store - used for determining vertical scrollTo position
        const infoBarObserver = new ResizeObserver(
            debounce((items) => {
                items.forEach((item) => {
                    if (!item.target === $infoBarEl) return;
                    this.$store.commit('setScrollToOffset', getResizeObserverEntryHeight(item));
                });
            })
        );
        infoBarObserver.observe($infoBarEl);

        // Keep track of the VS height in the store - used for preserving the space the sidebar when the VS is kept open
        const vehicleSelectorObserver = new ResizeObserver(
            debounce((items) => {
                items.forEach((item) => {
                    if (item.target !== $vehicleSelectorEl || !this.isVsOpen) return;
                    this.$store.commit('vehicleSelector/setVsProp', { prop: 'height', value: getResizeObserverEntryHeight(item) });
                });
            })
        );
        vehicleSelectorObserver.observe($vehicleSelectorEl);
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/mixins';
@import '~scssVariables/config';

.info-bar {
    padding: 10px $padding $padding;
    position: sticky;
    top: -2px;
    box-shadow: 0 2px 4px 0 $info-bar-shadow;
    border-top: 2px solid transparent;
    z-index: $z-index-info-bar;
    background-color: $white;

    &.stuck {
        z-index: $z-index-info-bar-stuck;
    }

    @media (max-width: 640px) {
        padding: 10px 0 20px;
        box-shadow: none;
        height: auto;
    }
}

.vehicle-selector-toggle {
    color: $green;
    background-color: $white;
    display: inline-flex;
    border: 1px solid $green;
    border-radius: $defaultBorderRadius;
    padding: 4px 10px;
    cursor: pointer;
    position: relative;
    align-items: center;
    z-index: 5;
    /* force to same line height */
    height: $ib-item-height;
    line-height: $ib-item-line-height;
    max-width: 100%;

    @include respond(sm, md) {
        width: 100%;
    }

    @media (max-width: 640px) {
        padding: 6px 10px;
    }

    &.open {
        border: $border-gray;
        @include drop-shadow;

        &:after {
            width: 100%;
            height: 14px;
            z-index: 16;
            display: block;
            content: '';
            top: 32px;
            left: 0;
            background-color: white;
            position: absolute;
            @media (max-width: 960px) {
                top: 36px;
            }
        }
    }

    &.forced {
        border-color: $required;
        z-index: 1092;
    }

    .vehicle-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.info-col {
    height: 41px;
    font-size: 0.75rem;
    width: auto;

    /* emulate columns in body content to match alignment */
    &.vs-wrap {
        margin-right: 2%;
        width: 27%;

        /* get rid of two col layout on tablet */
        @include respond-up-to(sm) {
            width: 10%;
            margin-right: 0;
        }

        @media (max-width: 640px) {
            width: 16%;
        }
    }
    &.search-goto {
        width: 70%;
    }
}
</style>
