<template>
    <div v-if="showBanner" class="container slide-in-bottom">
        <div class="d-flex">
            <div><img src="/images/OReillyProAppIcon.webp" height="60" :alt="fcoM('common.oreillyPro', 'O\'Reilly Pro')" class="app-rounded" /></div>
            <div class="ml-3 mt-1">
                <div class="mb-0">{{ fcoM('common.oreillyPro', 'O\'Reilly Pro') }}</div>
                <div class="small text-muted mt-0">{{ fcoM('common.oreillyProTagline', 'Dedicated to the professional') }}</div>
            </div>
            <div class="ml-auto">
                <a href="javaScript:void(0)" @click="handleCloseBanner">
                    <Icon name="times" class="black" />
                </a>
            </div>
        </div>
        <div class="d-flex mt-3">
            <a v-if="isIphoneNotSafari" href="itms-apps://itunes.apple.com/app/id6467030803" class="ml-auto">
                <img
                    v-if="locale === localeConstant.ES"
                    src="/images/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217.svg"
                    :alt="fcoM('mobileApp.appleStoreAlt', 'Download on the App Store')"
                    height="40px"
                />
                <img
                    v-else
                    src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    :alt="fcoM('mobileApp.appleStoreAlt', 'Download on the App Store')"
                    height="40px"
                />
            </a>
            <a v-if="isAndroid" href="https://play.google.com/store/apps/details?id=com.oreillyauto.professional" class="ml-auto">
                <img
                    v-if="locale === localeConstant.ES"
                    src="/images/GetItOnGooglePlay_Badge_Web_color_Spanish.png"
                    :alt="fcoM('mobileApp.androidStoreAlt', 'Get it on Google Play')"
                    height="40px"
                />
                <img v-else src="/images/GetItOnGooglePlay_Badge_Web_color_English.png" :alt="fcoM('mobileApp.androidStoreAlt', 'Get it on Google Play')" height="40px" />
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/common/components/Icon.vue';
import { Locale as localeConstant } from '@/common/constants/user';
import { getStorageItem, setStorageItem, StorageKey } from '@/common/store/store-utils';

const ua = navigator.userAgent;

export default {
    name: 'BaseApp',
    components: { Icon },
    data() {
        return {
            isAndroid: /Android/.test(ua),
            isIphoneNotSafari: /iPhone/.test(ua) && /(Cr|Fx|Edg)iOS/.test(ua),
            showBanner: false,
            localeConstant,
        };
    },
    computed: {
        ...mapState('i18n', ['locale']),
    },
    methods: {
        handleCloseBanner() {
            setStorageItem(StorageKey.HIDE_MOBILE_APP_BANNER, true);
            this.showBanner = false;
        },
    },
    mounted() {
        const hideMobileAppBanner = getStorageItem(StorageKey.HIDE_MOBILE_APP_BANNER);

        if (!hideMobileAppBanner && (this.isAndroid || this.isIphoneNotSafari)) this.showBanner = true;
    },
};
</script>

<style scoped lang="scss">
.app-rounded {
    border-radius: 10px;
}

.slide-in-bottom {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
    animation: slide-in 1s ease-out forwards;
    z-index: 100;
}

@keyframes slide-in {
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
}
</style>
