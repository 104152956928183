var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "FcoModal",
    {
      attrs: {
        closeMsg: _vm.fcoM("common.close", "Close"),
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        {
          attrs: {
            slot: "body",
          },
          slot: "body",
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.fcoM(
                  "rs.quote.newQuotePrompt",
                  "The quote currently has products in it, do you want to start a new quote or add to the existing quote?"
                )
              )
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          attrs: {
            slot: "footer",
          },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "button secondary font-weight-bold",
              attrs: {
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("start-new-quote")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fcoM("rs.quote.startNew", "Start New Quote")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button font-weight-bold ml-2",
              attrs: {
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("use-existing-quote")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.fcoM(
                      "rs.quote.addToExistingQuote",
                      "Add To Existing Quote"
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }