<template>
    <header class="fco-header public-header">
        <h1 class="sr-only">{{ fcoM('rs.home.publicHeader', 'O\'Reilly Pro, Auto Parts for the Professional') }}</h1>
        <div class="fco-header-container d-md-flex">
            <HeaderBrandLink />
            <div class="fco-header-right d-none d-md-flex">
                <div class="pro-services d-none d-md-block">{{ fcoM('rs.home.professionalServices', 'Professional Services') }}</div>
            </div>
        </div>
    </header>
</template>

<script>
import HeaderBrandLink from './HeaderBrandLink.vue';

export default {
    name: 'PublicHeader',
    components: { HeaderBrandLink },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/mixins';
@import '~scssVariables/config';

.fco-header-container {
    @include container-wide;

    @include respond-up-to(sm) {
        padding: 0;
    }
}
</style>
