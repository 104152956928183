var render = function render() {
  var _vm$vsData, _vm$vsData$states
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm.alertMessage
      ? _c(
          "div",
          {
            staticClass: "alert alert-warning px-3 py-1",
          },
          [_vm._v(_vm._s(_vm.alertMessage))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: {
          id: "searchPTV",
        },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handlePTVSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "label",
          {
            attrs: {
              for: "plate",
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.fcoM(
                  "rs.homeMain.lookupbystate",
                  "Lookup By License Plate"
                ) || " "
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex",
          },
          [
            !(
              (_vm$vsData = _vm.vsData) !== null &&
              _vm$vsData !== void 0 &&
              (_vm$vsData$states = _vm$vsData.states) !== null &&
              _vm$vsData$states !== void 0 &&
              _vm$vsData$states.length
            ) || !_vm.defaultStateValue
              ? _c(
                  "div",
                  {
                    staticClass: "vs-state-select",
                  },
                  [
                    _c("VueSkeletonLoader", {
                      staticClass: "custom-select",
                      attrs: {
                        type: "rect",
                        radius: 3,
                        rounded: true,
                        width: "100%",
                        height: "100%",
                      },
                    }),
                  ],
                  1
                )
              : _c("FcoSearchSelect", {
                  ref: "stateSelect",
                  staticClass: "vs-state-select",
                  attrs: {
                    value: _vm.plateState,
                    "allow-empty": false,
                    options: _vm.vsData.states,
                    placeholder: _vm.tempState,
                    "data-qa": "ptv-state-select",
                    "menu-container": _vm.$refs.stateSelect,
                  },
                  on: {
                    focus: _vm.handleStateFocus,
                    blur: _vm.handleBlur,
                    select: _vm.handleStateSelect,
                  },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "input-group",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.licensePlate,
                      expression: "licensePlate",
                    },
                  ],
                  class: {
                    error: _vm.showPtvValidationError,
                  },
                  attrs: {
                    type: "text",
                    id: "plate",
                    "data-qa": "ptv-plate-input",
                    placeholder: _vm.fcoM(
                      "common.vehicleLicensePlate",
                      "License Plate #"
                    ),
                  },
                  domProps: {
                    value: _vm.licensePlate,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.licensePlate = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gtm-license-number button input-group-append px-2 px-xl-3 py-1 mb-0",
                    attrs: {
                      "data-qa": "ptv-submit-button",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-sm-none d-lg-inline",
                      },
                      [_vm._v(_vm._s(_vm.fcoM("common.go", "Go") || " "))]
                    ),
                    _vm._v(" "),
                    _c("Icon", {
                      staticClass: "gtm-license-number-svg",
                      attrs: {
                        name: "chevron-right",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showPtvValidationError
          ? _c(
              "span",
              {
                staticClass: "error",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.showPtvValidationErrorMessage) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.showNewQuotePrompt
          ? _c("QuoteSelectionModal", {
              on: {
                close: function ($event) {
                  _vm.showNewQuotePrompt = false
                },
                "start-new-quote": function ($event) {
                  return _vm.handleQuotePromptAnswer(false)
                },
                "use-existing-quote": function ($event) {
                  return _vm.handleQuotePromptAnswer(true)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("FcoModal", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showModal,
              expression: "showModal",
            },
          ],
          attrs: {
            titleText: _vm.fcoM(
              "rs.homeMain.pleaseSelectVehicle",
              "Please select your vehicle"
            ),
            closeMsg: _vm.fcoM("common.close", "Close"),
          },
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "body",
              fn: function () {
                return [
                  _vm.showModalLoading
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "m-3",
                            attrs: {
                              color: "green",
                              name: "refresh",
                              size: "large",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.mainMessage))]),
                        _vm._v(" "),
                        _vm.vehicles.length
                          ? _c(
                              "ul",
                              [
                                _vm._l(
                                  _vm.cleanVehicleList,
                                  function (vehicle) {
                                    return _c(
                                      "li",
                                      {
                                        key: vehicle.vin,
                                        staticClass: "my-1",
                                        attrs: {
                                          "data-qa": "ptv-modal-results-list",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)",
                                              "data-qa":
                                                "ptv-modal-vehicle-link",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleVehicleClick(
                                                  vehicle
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(vehicle.yearMakeModel)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "javascript:void(0)",
                                        "data-qa": "ptv-modal-no-match-link",
                                      },
                                      on: {
                                        click: _vm.forceNewRequest,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fcoM(
                                            "rs.homeMain.noneAreVehicle",
                                            "None of these are my vehicle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "button secondary",
                      attrs: {
                        type: "button",
                        "data-qa": "close-modal-button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showModal = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.fcoM("common.close", "Close")) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }