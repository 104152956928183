<template>
    <div>
        <template v-if="!isChatDisabled">
            <a v-if="isChatReady" href="javascript:void(0)" :class="buttonClass" @click.prevent.stop="openChat">
                <Icon name="comments-o" :class="'clickable text-md align-text-bottom'" />{{ label }}
            </a>
            <VueSkeletonLoader v-else type="rect" :width="125" :height="18" :rounded="true" />
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Icon from './Icon.vue';
import { openChat } from '../utilities/oracleChat/oracleChat';

export default {
    name: 'ChatButton',
    components: { VueSkeletonLoader, Icon },
    props: {
        label: {
            type: String,
            required: true,
        },
        buttonClass: {
            type: String,
            default: 'text-color-inherit text-decoration-none',
        },
    },
    computed: {
        ...mapState(['isChatReady', 'isChatDisabled']),
    },
    methods: {
        openChat,
    },
};
</script>

<style scoped lang="scss">
a {
    .icon {
        margin-right: 3px;
        margin-bottom: 1px;
    }
}
</style>
