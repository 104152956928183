import { ErrorKeys } from '../constants/login';

export default {
    computed: {
        loginErrorKey() {
            if (!this.isSPA) {
                return new URLSearchParams(window.location.search).get('errorKey');
            }
            return this.$route.query.errorKey;
        },
        loginErrorMessagesByKey() {
            return {
                [ErrorKeys.OAUTH_LOGIN_ERROR]: this.fcoM('oauth.loginError', 'An error occurred, please login again'),
                [ErrorKeys.PROMO_ADMIN_LOGIN_ERROR]: this.fcoM('promoAdmin.loginError', 'An error accessing Promo Admin has occurred, please try again later.'),
                [ErrorKeys.LOGIN_AUTHENTICATION_FAILED]: this.fcoM('rs.home.incorrectLogin', 'Your username and/or password is incorrect. Please try again.'),
                [ErrorKeys.INVALID]: this.fcoM('rs.home.incorrectLogin', 'Your username and/or password is incorrect. Please try again.'),
                [ErrorKeys.LOGIN_REQUIRED]: this.fcoM('rs.home.loginRequired', 'Please login to continue'),
                [ErrorKeys.SHOP_REFERRAL_LOGIN_ERROR]: this.fcoM('rs.home.sso.shopreferral.loginError', 'An error accessing Shop Referral has occurred. Please try again later.'),
                [ErrorKeys.STATEMENTS_LOGIN_ERROR]: this.fcoM('rs.home.sso.statements.loginError', 'An error accessing Statements has occurred. Please try again later.'),
                [ErrorKeys.LOGIN_TEMP_PASSWORD_EXPIRED]: this.fcoM('rs.home.temporaryPasswordExpired', 'The temporary password for this username has expired. Please reset the password associated with this username again.'),
            };
        },
        loginErrorMessage() {
            if (!this.loginErrorKey) return '';
            return this.loginErrorMessagesByKey[this.loginErrorKey] || '';
        },
    },
};
