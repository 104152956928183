import { Validator } from 'vee-validate';
import store from '../store/store';
import { Locale } from '../constants/user';
import { setLivePersonLocale } from '../utilities/liveperson';
import { fcoM as fcoMessage } from '../utilities/fcoI18n';

// List of additional message resources by locale.
// This should represent a complete index of languages we support
function getLocaleMessages(locale) {
    if (!getLocaleMessages.cache) {
        getLocaleMessages.cache = {};
    }

    const mapDefaultMessagesExport = ({ default: defaultExport } = {}) => defaultExport || {};
    let request = getLocaleMessages.cache[locale];

    if (!request) {
        if (locale === Locale.EN) {
            request = import('../../locales/en.json').then(mapDefaultMessagesExport);
        } else if (locale === Locale.ES) {
            request = import('../../locales/es.json').then(mapDefaultMessagesExport);
        }
        getLocaleMessages.cache[locale] = request;
    }

    return request;
}

export const storageLocaleKey = 'locale';
export const fcoM = fcoMessage;

/**
 * Internationalization Plugin for FCO
 *      - registers a Vuex store module: 'i18n'
 *          - state contains the current locale and messages for that locale
 *          - changeLocale() action to switch locales and get updated messages
 *          - localeOptions getter provides list of value/label pairs of supported locales
 *          - other getters for checking against specific locales
 *      - adds the `fcoM` global mixin for referencing messages by key
 */
export default {
    install(Vue) {
        const isLocaleEnglish = (locale) => locale === Locale.EN;
        const isLocaleSpanish = (locale) => locale === Locale.ES;
        const isValidLocale = (locale) => isLocaleEnglish(locale) || Object.values(Locale).includes(locale);

        store.registerModule('i18n', {
            namespaced: true,
            state: {
                locale: Locale.EN,
                messages: {},
            },
            mutations: {
                setLocale(state, locale) {
                    if (!isValidLocale(locale)) return;
                    state.locale = locale;
                },
                setMessages(state, { locale, messages }) {
                    if (!isValidLocale(locale)) return;
                    state.messages[locale] = messages;
                },
            },
            actions: {
                async changeLocale({ commit, state, getters, dispatch }, { locale = Locale.EN, cache = true } = {}) {
                    // If we don't have messages for a given locale, fall back to English.
                    const validLocale = !isValidLocale(locale) ? Locale.EN : locale;

                    if (getters.hasLocale(locale) && validLocale === state.locale) return;

                    // TODO remove this line when we stop using LivePerson
                    setLivePersonLocale(locale);

                    // This check allows the language to be changed without saving the selection in localStorage
                    // (i.e. if feature is not enabled but user changes the language on the terms & conditions page)
                    if (cache) localStorage.setItem(storageLocaleKey, validLocale);

                    if (!getters.hasLocale(validLocale)) {
                        await dispatch('requestLocale', validLocale);
                    }

                    commit('setLocale', validLocale);
                    Validator.localize(validLocale);
                },
                async selectDefaultLocale({ dispatch }, localeOverride) {
                    const cachedLocale = localStorage.getItem(storageLocaleKey);
                    const browserDefaultLocale = navigator.language;
                    const defaultLocale = (localeOverride || cachedLocale || browserDefaultLocale || Locale.EN).split('-')[0].toLowerCase();
                    return dispatch('changeLocale', { locale: defaultLocale });
                },
                async requestLocale({ commit, getters }, locale = Locale.EN) {
                    if (!isValidLocale(locale) || getters.hasLocale(locale)) return;

                    const messages = await getLocaleMessages(locale);
                    commit('setMessages', { locale, messages });
                },
            },
            getters: {
                localeOptions: (state, getters) => [
                    { value: Locale.EN, label: getters.fcoM('common.english', 'English') || '' },
                    { value: Locale.ES, label: getters.fcoM('common.espanol', 'Español') || '' },
                ],
                isLocaleEnglish: ({ locale }) => isLocaleEnglish(locale),
                isLocaleSpanish: ({ locale }) => isLocaleSpanish(locale),
                hasLocale:
                    ({ messages }) =>
                    (locale) =>
                        Object.prototype.hasOwnProperty.call(messages, locale) && Object.keys(messages[locale]).length,
                fcoM:
                    ({ locale, messages }) =>
                    (key, fallback = messages[Locale.EN]?.[key] || '') => {
                        if (locale === Locale.EN) return fallback;
                        return messages[locale]?.[key] || fallback;
                    },
            },
        });

        // Register the `fcoM` mixin for referencing messages by key in Vue components
        Vue.mixin({
            computed: {
                fcoM() {
                    return this.$store.getters['i18n/fcoM'];
                },
            },
        });
    },
};
