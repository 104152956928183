var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "ul",
    [
      _vm.isLoading
        ? _vm._l(_vm.numberOfPlaceholders, function (n) {
            return _c(
              "li",
              {
                key: `${_vm.name}_loader_${n}`,
              },
              [
                _c("SkeletonText", {
                  staticClass: "mw-100",
                  attrs: {
                    maxWidth: _vm.placeholderMaxWidth,
                    minWidth: _vm.placeholderMinWidth,
                  },
                }),
              ],
              1
            )
          })
        : _vm._l(_vm.menu.menuItems, function (menuItem, index) {
            return _c(
              "li",
              {
                key: `${menuItem.title}_${index}`,
              },
              [
                menuItem.linkToFile
                  ? _c("DotCmsLink", {
                      attrs: {
                        fileName: menuItem.linkToFile.fileName,
                        label: menuItem.title,
                      },
                    })
                  : _c(
                      "a",
                      {
                        attrs: {
                          href: menuItem.linkUrl,
                        },
                      },
                      [_vm._v(_vm._s(menuItem.title))]
                    ),
                _vm._v(" "),
                menuItem.icon
                  ? _c("DotCmsImage", {
                      staticClass: "d-inline",
                      style: _vm.getMenuItemStyle(menuItem),
                      attrs: {
                        src: `${_vm.cmsData.url}${menuItem.icon.fileAsset.versionPath}`,
                        alt: "",
                        width: menuItem.iconScaleWidth || -1,
                        height: menuItem.iconScaleHeight || -1,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }