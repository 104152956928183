import { mapGetters } from 'vuex';
import { fcoUrl } from 'fcoModules/utilities';
import axios from 'axios';

export default {
    computed: {
        ...mapGetters(['isAuthorizedUser']),
    },
    watch: {
        isAuthorizedUser: {
            immediate: true,
            async handler() {
                if (!this.isAuthorizedUser) return;
                try {
                    const { data: hasDealerControlOrIsDemo } = await axios.get(fcoUrl('/current/hasDealerControlOrIsDemo'));
                    if (!hasDealerControlOrIsDemo) {
                        this.$fcoToast.error(this.fcoM('oauth.noDealerControl', 'There is a problem with your O\'Reilly Pro access. For assistance, please contact our support team by chat, by email at firstcallsupport@oreillyauto.com, or by phone at 1-800-934-2451.'), { autoClose: false });
                    }
                } catch {
                    this.$fcoToast.error(this.fcoM('oauth.noDealerControlError', 'An error occurred while retrieving your O\'Reilly Pro access. Please try again.'));
                }
            },
        },
    },
};
