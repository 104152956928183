<template>
    <ValidationObserver ref="validator" v-bind="$attrs" v-on="$listeners">
        <template #default="slotScope">
            <slot v-bind="slotScope"></slot>
        </template>
    </ValidationObserver>
</template>
<script>
import { Validator } from 'vee-validate';
import { nextTick } from 'vue';

export default {
    name: 'OpcValidationObserver',
    computed: {
        currentLocale() {
            return this.$store.state.i18n.locale;
        },
    },
    methods: {
        async validateOnlyWithErrors() {
            const observer = this.$refs.validator;

            if (observer) {
                await nextTick();
                const providers = observer.refs;
                const validationPromises = Object.keys(providers)
                    .filter((key) => providers[key].flags.invalid)
                    .map((key) => providers[key].validate());
                await Promise.all(validationPromises);
            }
        },
        validate() {
            return this.$refs.validator.validate();
        },
        reset() {
            this.$refs.validator.reset();
        },
        getErrorFields() {
            const errorObj = this.$refs.validator?.ctx?.errors;
            const errorFields = [];
            for (const [key, value] of Object.entries(errorObj)) {
                if (value.length >= 1) errorFields.push(key);
            }
            return errorFields;
        },
        getInvalidObservers() {
            return this.$refs.validator.observers.find((observer) => observer.ctx.invalid);
        },
    },
    watch: {
        currentLocale() {
            Validator.localize(this.currentLocale);
            this.validateOnlyWithErrors();
        },
    },
};
</script>
