var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "carousel slide",
      attrs: {
        "aria-live": _vm.isAutoplaying ? "off" : "polite",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "position-relative",
        },
        [
          _c(
            "div",
            {
              staticClass: "carousel-inner",
            },
            _vm._l(_vm.slides, function (slide, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: _vm.getSlideClass(index),
                  on: {
                    focusin: _vm.stopAutoplay,
                  },
                },
                [_vm._t("default", null, null, slide)],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.disableControls && _vm.slideCount > 1
            ? [
                _c(
                  "button",
                  {
                    staticClass: "carousel-control-prev",
                    on: {
                      click: _vm.handlePreviousClick,
                      focus: _vm.stopAutoplay,
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "chevron-left",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "carousel-control-next",
                    on: {
                      click: _vm.handleNextClick,
                      focus: _vm.stopAutoplay,
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "chevron-right",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.disableNav && _vm.slideCount > 1
        ? _c(
            "div",
            {
              staticClass: "carousel-nav",
              on: {
                focusin: _vm.stopAutoplay,
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "carousel-nav-control highlight-on-focus",
                  on: {
                    click: _vm.handlePreviousClick,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "sr-only",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.fcoM(
                            "carousel.previousSlide",
                            "Previous carousel slide"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "carousel-nav-icon",
                    attrs: {
                      name: "chevron-left",
                      "aria-hidden": "true",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.slideCount, function (itemNumber) {
                return _c(
                  "button",
                  {
                    key: itemNumber,
                    class: [
                      "carousel-nav-indicator highlight-on-focus",
                      {
                        active: itemNumber - 1 === _vm.activeIndex,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.advanceSlidesBy(
                          itemNumber - 1 - _vm.activeIndex
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.stringFormat(
                            _vm.fcoM(
                              "carousel.slideNumber",
                              "Slide {0} of {1}"
                            ),
                            itemNumber,
                            _vm.slideCount
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAutoplaying,
                      expression: "isAutoplaying",
                    },
                  ],
                  staticClass: "carousel-nav-control highlight-on-focus",
                  on: {
                    click: _vm.stopAutoplay,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "sr-only",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.fcoM("carousel.pause", "Pause carousel cycle")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "carousel-nav-icon",
                    attrs: {
                      name: "pause",
                      "aria-hidden": "true",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isAutoplaying,
                      expression: "!isAutoplaying",
                    },
                  ],
                  staticClass: "carousel-nav-control highlight-on-focus",
                  on: {
                    click: _vm.startAutoplay,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "sr-only",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.fcoM("carousel.resume", "Resume carousel cycle")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "carousel-nav-icon",
                    attrs: {
                      name: "play",
                      "aria-hidden": "true",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "carousel-nav-control highlight-on-focus",
                  on: {
                    click: _vm.handleNextClick,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "sr-only",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.fcoM("carousel.next", "Next carousel slide"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "carousel-nav-icon",
                    attrs: {
                      name: "chevron-right",
                      "aria-hidden": "true",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }