import { Validator } from 'vee-validate';
import { fcoM } from './fcoI18n';
import * as test from '../validation/tests';

/**
 * Custom validation rules
 * The property name is the name of the rule.
 */
const customRules = {
    basicChars: {
        getMessage: () => fcoM('validation.invalidInput', 'Invalid input - Special characters not allowed.'),
        validate: test.isBasicChars,
    },
    basicCharsMultiline: {
        getMessage: () => fcoM('validation.invalidInput', 'Invalid input - Special characters not allowed.'),
        validate: test.isBasicCharsMultiline,
    },
    personName: {
        getMessage: () => fcoM('validation.invalidInput', 'Invalid input - Special characters not allowed.'),
        validate: test.isPersonName,
    },
    poMask: {
        getMessage: () => fcoM('validation.poNumber', 'Please enter a valid PO mask.'),
        validate: test.isValidPoMask,
    },
    poMaskLength: {
        getMessage: () => fcoM('validation.poNumberLength', 'PO Numbers cannot exceed 22 characters.'),
        validate: test.isValidPoMaskLength,
    },
    loginName: {
        getMessage: () => fcoM('validation.invalidInput', 'Invalid input - Special characters not allowed.'),
        validate: test.isValidLoginName,
    },
    numberWithCommas: {
        getMessage: () => fcoM('validation.numberWithCommas', 'Please enter a valid number.'),
        validate: test.isNumberWithCommas,
    },
    decimalPlaces: {
        getMessage: () => fcoM('validation.decimalPlaces', 'Value must have {0} or less decimal places.'),
        validate: (value, args) => {
            const parts = value.toString().split('.');

            if (parts.length > 1) {
                return parts[1].length <= args[0];
            }

            return true;
        },
    },
    usPhone: {
        getMessage: () => fcoM('validation.phoneUS', 'Please specify a valid phone number.'),
        validate: test.isUsPhone,
    },
    zipCode: {
        getMessage: () => fcoM('validation.zip', 'Please enter a valid zip code.'),
        validate: test.isValidZipCode,
    },
    fcoEmail: {
        getMessage: () => fcoM('validation.email', 'Please enter a valid e-mail address.'),
        validate: test.isFcoEmail,
    },
    vin: {
        getMessage: () => fcoM('validation.vin', 'Please enter a valid VIN number.'),
        validate: test.isValidVIN,
    },
    noSpace: {
        getMessage: () => fcoM('validation.noSpace', 'Value may not include the space character.'),
        validate: test.hasNoSpaces,
    },
};

/**
 * Rule registration
 */
const ruleKeys = Object.keys(customRules);
ruleKeys.forEach((ruleKey) => {
    Validator.extend(ruleKey, customRules[ruleKey]);
});

export const localize = (locale, messages) => {
    Validator.localize(locale, {
        locale,
        messages: {
            ...messages,

            // For any custom rules, use the message provided by the backend
            ...ruleKeys.reduce((mappedMessages, ruleKey) => {
                mappedMessages[ruleKey] = customRules[ruleKey].getMessage;
                return mappedMessages;
            }, {}),
        },
    });
};
