/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
/**
 * The base store for the FCO multi-page application.
 * All apps within FCO can leverage this store to get core data about the app, user, account, etc.
 *
 * If an app needs to add modules to this store, dynamic module registration can be leveraged.
 * https://vuex.vuejs.org/guide/modules.html#dynamic-module-registration
 *
 * Individual store properties (state, mutations, actions, etc.) are exported mainly for testing purposes.
 * Unless our backend data structure dictates otherwise, they should not be imported into another store for the sake of extending this one.
 * We should try to keep app-level state in one place.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import delay from 'fco/src/utils/delay';

import { fcoUrl, secondsToMs } from '@/fcoModules/utilities';
import { isPlainObject } from 'fco/dist/utils/types';
import { selectShop, getShopDetailsById, getShopsByUserId } from '../services/shopService';
import {
    createRequestStatus,
    requestSettled,
    trackRequestStatus,
    updateRequestStatus,
    isRequestIdle,
    isRequestPending,
    requestStatus,
} from './request-status';
import {
    declineUserEmailUpdate,
    getCurrentUser,
    getCurrentUserLegacy,
    masquerade,
    masqueradeLegacy,
    saveUserEmail,
    saveUserPreferences,
    stopMasquerade,
    stopMasqueradeLegacy,
} from '../services/userService';
import { saveCompanyPreferences } from '../services/companyService';
import { getFeatures, getFeaturesForStore } from '../services/featuresService';
import { setLivePersonAuthenticated } from '../utilities/liveperson';
import { Locale, Role } from '../constants/user';

// Store Modules
import vehicleSelector from './modules/vehicleSelector';
import miniQuote from './modules/miniQuote';
import stockOrderQuickAdd from './modules/stockOrderQuickAdd';
import opp from './modules/opp';
import kits from './modules/kits';
import partSelection from './modules/partSelection';
import usageTracking from './modules/usageTracking';
import {
    getStorage,
    getStorageItem,
    hasStorageItem,
    getStorageItemLastRefresh,
    isStorageItemExpired,
    removeStorageItem,
    setStorageItem,
    StorageKey,
    CacheKey,
    cacheExpirationByKey,
    setStorageItemLastRefresh,
    requestCacheTime,
    getCacheRefreshKey,
} from './store-utils';

Vue.use(Vuex);

const isSPA = Boolean(window.fcoSPA);

const setAuthorizationHeader = (authData) => {
    if (!authData) {
        delete axios.defaults.headers.common.Authorization;
    } else {
        const { access_token: accessToken } = authData;
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
};

/**
 * Adds expiration date/time properties for the access and refresh tokens.
 * Without this, all we would have is the time until expiration from when the token was originally created.
 * That is not useful to us because when a user returns or refreshes the page, we'll lose the original context.
 * This also simplifies the math required every time we want to check if the tokens are expired.
 */
const authWithExpiration = (auth) => {
    if (!auth) return null;

    // Avoid adding expiration times if the auth object already has it (i.e. retrieved from localStorage)
    if (Object.prototype.hasOwnProperty.call(auth, 'accessExpiresOn')) return auth;

    const now = Date.now();
    const fiveMinutes = 300000;
    return {
        ...auth,
        accessExpiresOn: now + auth.access_expiration - fiveMinutes,
        refreshExpiresOn: now + auth.refresh_expiration - fiveMinutes,
    };
};

const cachedAuth = getStorageItem(StorageKey.AUTH);
setAuthorizationHeader(cachedAuth);

const getDefaultUser = () =>
    isSPA
        ? null
        : {
              authenticated: false,
              carCustomer: false,
              customerType: {
                  description: '',
                  name: '',
              },
              customerTypeSms: false,
              emailAddress: '',
              emailUpdateRequired: false,
              hideStorePhoneNumber: false,
              firstName: '',
              lastName: '',
              loginName: '',
              masquerade: false,
              partsPayoffAccessible: false,
              passwordChangeRequired: false,
              shopReferralEligible: false,
              teamMember: false,
              userId: null,
              userPreference: {
                  languageCode: Locale.EN,
                  catalogNavigationTab: 1,
                  selectedStylesheet: 1,
                  selectedShopId: 0,
              },
              group: '',
          };

const getDefaultCompany = () => ({
    demo: false,
    id: 0,
    address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        flatAddress: '',
        completeAddress: false,
        notEmpty: false,
        combinedStreetAddress: '',
    },
    companyPreference: {
        garageBayIdVisible: false,
        garageBayIdRequired: false,
        vehicleFleetIdVisible: false,
        vehicleFleetIdRequired: false,
        poVisible: false,
        poRequired: false,
        companyId: 0,
        id: 0,
        poValidationMask: '',
    },
    accountStatus: '',
    shopRequests: [
        {
            garageIdRequired: false,
            garageBayIdVisible: false,
            poRequired: false,
            poVisible: false,
            vehicleFleetIdRequired: false,
            vehicleFleetIdVisible: false,
            requested: false,
            approved: false,
            pending: false,
            declined: false,
            arAccountNumber: 0,
            shopAddress: {
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                flatAddress: '',
                completeAddress: false,
                notEmpty: false,
                combinedStreetAddress: '',
            },
            laborRate: 0,
            pricingTypeModification: 0,
            taxRateLabor: 0,
            taxRateParts: 0,
            companyId: 0,
            notes: [
                {
                    id: 0,
                    text: '',
                    createdByUser: {
                        firstName: '',
                        lastName: '',
                        loginName: '',
                    },
                    createDateTime: '',
                    internal: false,
                    shopRequestId: 0,
                },
            ],
            id: 0,
            pricingMethod: '',
            pricingType: '',
            requestStatus: '',
            customerLocationId: '',
            miscInfo: '',
            poValidationMask: '',
            shopFax: '',
            shopName: '',
            shopPhone: '',
            createDateTime: '',
        },
    ],
    accountName: '',
    arGroupCode: '',
    shopReferralEligible: false,
    partsPayoffVisible: false,
    paymentMethod: '',
});

const getDefaultCmsData = () => ({ url: '', token: '' });

export const state = {
    isSPA,
    auth: cachedAuth,
    csrfObject: {
        parameterName: '_csrf',
        headerName: 'X-CSRF-TOKEN',
        token: '',
    },
    cxmlCallbackUrl: getStorageItem(StorageKey.CXML_CALLBACKURL) || '',
    transactionId: getStorageItem(StorageKey.TRANSACTION_ID) || '',
    buyerCookie: getStorageItem(StorageKey.BUYER_COOKIE) || '',
    googleMapsKey: '',
    imageServerUrl: 'https://images.firstcallonline.com',
    promoImagesUrl: '',
    teamviewerAndroidURL: '',
    teamviewerChromeURL: '',
    teamviewerIosURL: '',
    teamviewerMacUrl: '',
    teamviewerWindowsURL: '',
    supportEmail: 'firstcallsupport@oreillyauto.com',
    version: {
        copyright: `Copyright © 2011-${new Date().getFullYear()}`,
        fcoVersion: '',
        node: '',
        ocatVersion: '',
    },
    user: getStorageItem(StorageKey.USER) || getDefaultUser(),
    stickySessionId: getStorageItem(StorageKey.STICKY_SESSION_ID),
    isPasswordChangeRequired: getStorageItem(StorageKey.PASSWORD_CHANGE_REQUIRED),
    isTermsAcceptRequired: getStorageItem(StorageKey.TERMS_ACCEPT_REQUIRED),
    masqueradeRealUser: getStorageItem(StorageKey.MASQUERADE_REAL_USER),
    masqueradeSource: getStorage().getItem(StorageKey.MASQUERADE_SOURCE),
    isCostHidden: getStorageItem(StorageKey.IS_COST_HIDDEN) || false,
    isEarnbackEligible: null,
    isChatReady: false,
    isChatDisabled: false,
    isChatButtonHidden: getStorageItem(StorageKey.IS_CHAT_BUTTON_HIDDEN) || false,
    shops: [],
    currentShop: null,
    shopRequests: [],
    USStates: [],
    jobTitles: [],
    customerTypes: [],
    company: getDefaultCompany(),
    scrollToOffset: 0,
    profile: '',
    cmsData: getDefaultCmsData(),
    features: getStorageItem(StorageKey.FEATURES) || [],
    featuresForStore: getStorageItem(StorageKey.FEATURES_FOR_STORE) || [],
    tsm: {
        tsmEmailAddress: '',
        tsmFirstName: '',
        tsmLastName: '',
        tsmNumber: 0,
    },
    requests: {
        getAppData: createRequestStatus(),
        getUser: createRequestStatus({
            storageKey: StorageKey.USER,
            pollKey: 'user',
            pollValue: ({ getters }) => getters.isAuthorizedUser,
        }),
        getUserShops: createRequestStatus(),
        getCurrentShop: createRequestStatus({
            cacheKey: CacheKey.CURRENT_SHOP,
            pollKey: 'shop',
            pollValue: ({ getters }) => getters.selectedShopId,
        }),
        getStickySessionId: createRequestStatus(),
        getTSM: createRequestStatus(),
        getFeatures: createRequestStatus({
            storageKey: StorageKey.FEATURES,
            pollKey: 'features',
        }),
        getFeaturesForStore: createRequestStatus({
            storageKey: StorageKey.FEATURES_FOR_STORE,
            pollKey: 'featuresByStore',
            pollValue: ({ state }) => state.currentShop?.homeStore?.storeId,
        }),
        getCMSData: createRequestStatus(),
        getUSStates: createRequestStatus(),
        getJobTitles: createRequestStatus(),
        getCustomerTypes: createRequestStatus(),
        getShopRequests: createRequestStatus(),
        getCompany: createRequestStatus({
            cacheKey: CacheKey.COMPANY,
            pollKey: 'company',
            pollValue: ({ state }) => state.company?.id,
        }),
        getIsEarnbackEligible: createRequestStatus(),
    },
};

const mutations = {
    updateRequestStatus,
    setUSStates(state, value) {
        state.USStates = value;
    },
    setJobTitles(state, value) {
        state.jobTitles = value;
    },
    setCustomerTypes(state, value) {
        state.customerTypes = value;
    },
    setCompany(state, data) {
        state.company = data;
    },
    setAuthTokens(state, auth) {
        state.auth = authWithExpiration(auth) || null;
        setAuthorizationHeader(state.auth);

        if (!state.auth) {
            removeStorageItem(StorageKey.AUTH);
        } else {
            setStorageItem(StorageKey.AUTH, state.auth);
        }
    },
    setCxmlCallbackUrl(state, cxmlCallbackUrl) {
        state.cxmlCallbackUrl = cxmlCallbackUrl;
        if (!state.cxmlCallbackUrl) {
            removeStorageItem(StorageKey.CXML_CALLBACKURL);
        } else {
            setStorageItem(StorageKey.CXML_CALLBACKURL, state.cxmlCallbackUrl);
        }
    },
    setTransactionId(state, transactionId) {
        state.transactionId = transactionId;
        if (!state.transactionId) {
            removeStorageItem(StorageKey.TRANSACTION_ID);
        } else {
            setStorageItem(StorageKey.TRANSACTION_ID, state.transactionId);
        }
    },
    setBuyerCookie(state, buyerCookie) {
        state.buyerCookie = buyerCookie;
        if (!state.buyerCookie) {
            removeStorageItem(StorageKey.BUYER_COOKIE);
        } else {
            setStorageItem(StorageKey.BUYER_COOKIE, state.buyerCookie);
        }
    },
    setUser(state, user) {
        state.user = user?.userId ? user : getDefaultUser();

        if (!state.user) {
            removeStorageItem(StorageKey.USER);
        } else {
            setStorageItem(StorageKey.USER, state.user);
        }
    },
    setStickySessionId(state, stickySessionId) {
        state.stickySessionId = stickySessionId;
        if (stickySessionId == null) {
            removeStorageItem(StorageKey.STICKY_SESSION_ID);
        } else {
            setStorageItem(StorageKey.STICKY_SESSION_ID, stickySessionId);
        }
    },
    setMasqueradeRealUser(state, masqueradeRealUser) {
        state.masqueradeRealUser = masqueradeRealUser;
        if (!state.masqueradeRealUser) {
            removeStorageItem(StorageKey.MASQUERADE_REAL_USER);
        } else {
            setStorageItem(StorageKey.MASQUERADE_REAL_USER, state.masqueradeRealUser);
        }
    },
    setMasqueradeSource(state, masqueradeSource) {
        state.masqueradeSource = masqueradeSource;
        if (!masqueradeSource) removeStorageItem(StorageKey.MASQUERADE_SOURCE);
        else setStorageItem(StorageKey.MASQUERADE_SOURCE, masqueradeSource);
    },
    setShops(state, shops) {
        state.shops = shops || [];
    },
    setFeatures(state, features) {
        state.features = features || [];
        // Amount of time (ms) to cache features on the FE before they are considered expired.
        // Features will still be loaded in the background, even when cached, but this
        // allows us to immediately load up the correct UI features without having to wait
        // for the features request on subsequent page loads.
        const fifteenMinutes = 900000;
        setStorageItem(StorageKey.FEATURES, state.features, fifteenMinutes);
    },
    setFeaturesForStore(state, featuresForStore) {
        state.featuresForStore = featuresForStore || [];
        // Amount of time (ms) to cache features on the FE before they are considered expired.
        // Features will still be loaded in the background, even when cached, but this
        // allows us to immediately load up the correct UI features without having to wait
        // for the features request on subsequent page loads.
        const fifteenMinutes = 900000;
        setStorageItem(StorageKey.FEATURES_FOR_STORE, state.featuresForStore, fifteenMinutes);
    },
    setCurrentShop(state, value) {
        state.currentShop = !value ? null : { ...value };
    },
    setShopRequests(state, shopRequests) {
        state.shopRequests = shopRequests || [];
    },
    setAppData(
        state,
        {
            csrfObject,
            googleMapsKey,
            imageServerUrl,
            version,
            promoImagesUrl,
            profile,
            teamviewerAndroidURL,
            teamviewerChromeURL,
            teamviewerIosURL,
            teamviewerMacURL,
            teamviewerWindowsURL,
            supportEmail,
        }
    ) {
        state.csrfObject = csrfObject;
        state.googleMapsKey = googleMapsKey;
        state.imageServerUrl = imageServerUrl;
        state.version = version;
        state.promoImagesUrl = promoImagesUrl;
        state.profile = profile;
        state.teamviewerAndroidURL = teamviewerAndroidURL;
        state.teamviewerChromeURL = teamviewerChromeURL;
        state.teamviewerIosURL = teamviewerIosURL;
        state.teamviewerMacUrl = teamviewerMacURL;
        state.teamviewerWindowsURL = teamviewerWindowsURL;
        state.supportEmail = supportEmail || state.supportEmail;
        // In the multi-page Spring app, we need the CSRF with most of our requests. If a CSRF token was returned, assign it to the default axios header config.
        if (!state.isSPA && csrfObject?.token) {
            axios.defaults.headers.common['X-CSRF-Token'] = csrfObject.token;
        }
    },
    setIsCostHidden(state, value) {
        state.isCostHidden = value;
        setStorageItem(StorageKey.IS_COST_HIDDEN, state.isCostHidden);
    },
    setIsEarnbackEligible(state, isEarnbackEligible) {
        state.isEarnbackEligible = isEarnbackEligible;
        if (isEarnbackEligible === null) {
            removeStorageItem(StorageKey.IS_EARNBACK_ELIGIBLE);
        } else {
            setStorageItem(StorageKey.IS_EARNBACK_ELIGIBLE, state.isEarnbackEligible);
        }
    },
    setIsChatReady(state, value) {
        state.isChatReady = value;
    },
    setIsChatDisabled(state, value) {
        state.isChatDisabled = value;
    },
    setIsChatButtonHidden(state, value) {
        state.isChatButtonHidden = value;

        if (value === true) {
            const dayMilliseconds = 24 * 60 * 60 * 1000;

            setStorageItem(StorageKey.IS_CHAT_BUTTON_HIDDEN, true, dayMilliseconds);
        } else {
            removeStorageItem(StorageKey.IS_CHAT_BUTTON_HIDDEN);
        }
    },
    updateUserPreferences(
        state,
        {
            firstName = state.user.firstName,
            lastName = state.user.lastName,
            emailAddress = state.user.emailAddress,
            loginName = state.user.loginName,
            selectedStylesheet = state.user.userPreference?.selectedStylesheet,
            languageCode = state.user.userPreference?.languageCode,
            selectedShopId = state.user.userPreference?.selectedShopId,
        } = {}
    ) {
        state.user = {
            ...state.user,
            firstName,
            lastName,
            emailAddress,
            loginName,
            userPreference: {
                ...state.user.userPreference,
                selectedStylesheet,
                languageCode,
                selectedShopId,
            },
        };
        setStorageItem(StorageKey.USER, state.user);
    },
    updateCompanyPreferences(state, { accountName, demo, address, companyPreference }) {
        state.currentShop.company = {
            ...state.currentShop.company,
            accountName,
            address,
            demo,
            companyPreference,
        };
    },
    setUserEmailAddress(state, emailAddress) {
        state.user.emailAddress = emailAddress;
        setStorageItem(StorageKey.USER, state.user);
    },
    setUserEmailUpdateRequired(state, emailUpdateRequired) {
        if (state.user) {
            state.user.emailUpdateRequired = emailUpdateRequired;
        }
    },
    setScrollToOffset(state, value) {
        state.scrollToOffset = Number(value) || 0;
    },
    setCMSData(state, data) {
        state.cmsData = data;
    },
    setTSM(state, data) {
        state.tsm = data;
    },
    setPasswordChangeRequired(state, isRequired) {
        state.isPasswordChangeRequired = Boolean(isRequired);
        if (!state.isPasswordChangeRequired) {
            removeStorageItem(StorageKey.PASSWORD_CHANGE_REQUIRED);
        } else {
            setStorageItem(StorageKey.PASSWORD_CHANGE_REQUIRED, state.isPasswordChangeRequired);
        }
    },
    setTermsAcceptRequired(state, isRequired) {
        state.isTermsAcceptRequired = Boolean(isRequired);
        if (!state.isTermsAcceptRequired) {
            removeStorageItem(StorageKey.TERMS_ACCEPT_REQUIRED);
        } else {
            setStorageItem(StorageKey.TERMS_ACCEPT_REQUIRED, state.isTermsAcceptRequired);
        }
    },
    setCachedItemLastRefresh(state, { cacheKey, refreshedOn }) {
        const cacheTimeKey = getCacheRefreshKey(cacheKey);
        requestCacheTime[cacheTimeKey] = refreshedOn;
    },
};

let refreshTimeout;
const actions = {
    /**
     * Given a list of request names, dispatches (if idle) and awaits the corresponding requests.
     * This allows components to succinctly request and wait for data they depend on:
     * @example await this.$store.dispatch('requestIfIdle', ['getUser', 'getCompany'])
     */
    async requestIfIdle({ dispatch }, requestNames = []) {
        await dispatch('requestAll', { requests: requestNames, force: false });
    },
    /**
     * Given a list of request names, dispatches and awaits the corresponding requests.
     * If you only want to request if idle, use 'requestIfIdle' instead (see above).
     */
    async requestAll({ state, dispatch }, { requests = [], force = true }) {
        // map the requests so we can handle a mix of root and namespaced actions (i.e. 'miniQuote/getQuoteData')
        const namespacedRequests = requests.map((requestName) => {
            let namespace = '';
            let finalRequestName = requestName;
            if (requestName.indexOf('/') > -1) {
                [namespace, finalRequestName] = requestName.split('/');
            }
            return {
                action: requestName,
                requestName: finalRequestName,
                namespacedState: namespace ? state[namespace] : state,
            };
        });
        namespacedRequests.forEach(({ namespacedState, requestName, action }) => {
            if (force || isRequestIdle(namespacedState.requests[requestName])) dispatch(action);
        });
        await Promise.all(namespacedRequests.map(({ namespacedState, requestName }) => requestSettled(() => namespacedState.requests[requestName])));
    },
    async setAuthAndGetUser({ commit, dispatch }, auth) {
        clearTimeout(refreshTimeout);
        commit('setAuthTokens', auth);
        dispatch('startRefreshTimer');
        await dispatch('requestAll', { requests: ['getUser'] });
    },
    async refreshAuth({ state, commit, dispatch, getters }, abortWhenValid = false) {
        const refreshLockKey = 'fcoRefreshTokenLock';

        clearTimeout(refreshTimeout);

        // A mutex-style lock to prevent multiple simultaneous requests to refresh the token
        // If this is true, that means something else - either in this tab/window or another one - is already refreshing the token
        if (localStorage.getItem(refreshLockKey)) {
            // Retry the refresh request after a short delay.
            await delay(100);
            // Only refresh if the token hasn't already been updated.
            return dispatch('refreshAuth', true);
        }

        if (getters.isAuthExpired || !abortWhenValid) {
            try {
                localStorage.setItem(refreshLockKey, 'true');
                const { data } = await axios.get(fcoUrl('/auth/rest/refresh'), { headers: { Authorization: `Bearer ${state.auth.refresh_token}` } });
                commit('setAuthTokens', data);
            } finally {
                localStorage.removeItem(refreshLockKey);
            }
        }

        dispatch('startRefreshTimer');
    },
    logout({ state, commit, dispatch }, makeLogoutRequest = true) {
        clearTimeout(refreshTimeout);

        if (makeLogoutRequest && state.isSPA) {
            try {
                axios.get(fcoUrl(`/logout.html?${state.csrfObject.parameterName}=${state.csrfObject.token}`));
            } catch {
                // do nothing
            }
        }

        Object.keys(state.requests).forEach((requestName) => {
            commit('updateRequestStatus', { name: requestName, ...createRequestStatus() });
        });

        commit('setAuthTokens', null);
        dispatch('requestAll', { requests: ['getFeatures', 'getCMSData'] });
        commit('setUser', getDefaultUser());
        commit('setStickySessionId', null);
        commit('setIsEarnbackEligible', null);
        commit('setMasqueradeRealUser', null);
        commit('vehicleSelector/resetVehicleData');
        dispatch('partSelection/clearLookupDetails');
        dispatch('miniQuote/clearQuote');
        commit('setCurrentShop', null);
        commit('setCompany', getDefaultCompany());
        commit('setTSM', {});
        commit('setPasswordChangeRequired', false);
        commit('setTermsAcceptRequired', false);
        commit('setFeaturesForStore', []);

        // TODO remove this line when we stop using LivePerson
        setLivePersonAuthenticated(false);
    },
    startRefreshTimer({ state, dispatch }) {
        clearTimeout(refreshTimeout);

        const now = Date.now();
        const { accessExpiresOn, refreshExpiresOn } = state.auth || {};

        // If we have no refresh token or it's expired, bail out here
        if (!refreshExpiresOn || refreshExpiresOn <= now) return;

        const timeUntilAuthExpiration = accessExpiresOn - Date.now();
        refreshTimeout = setTimeout(() => {
            dispatch('refreshAuth');
        }, timeUntilAuthExpiration);
    },
    async getUser({ commit, getters, dispatch }) {
        const response = await trackRequestStatus(commit, 'getUser', isSPA ? getCurrentUser() : getCurrentUserLegacy());
        const userWithShops = response?.data || {};

        // The getCurrentUserLegacy() endpoint currently returns as 200 for unauthenticated users
        // but returns text/html because it's actually redirecting the request to the login page.
        // If no userId, it means we don't have a valid user, so treat it like a 401 and clear any cached session.
        // This is only necessary for the MPA, where we rely heavily on the BE for session data.
        if (!isSPA && !userWithShops?.userId && getters.isAuthorizedUser) {
            dispatch('logout');
            return;
        }

        commit('setUser', userWithShops);

        // TODO remove this when we stop using LivePerson
        // Alert LivePerson about user authentication (we refreshed the page)
        setLivePersonAuthenticated(getters.isAuthorizedUser);
    },
    async getStickySessionId({ commit }) {
        const response = await trackRequestStatus(commit, 'getStickySessionId', axios.get(fcoUrl('/current/stickySessionInfo')));
        if (!response?.data) return;
        const { excludedFromStickySession, userId, shopId } = response.data;
        if (!userId || !shopId) return;
        const stickySessionId = excludedFromStickySession ? `${userId}_${shopId}` : `${shopId}`;
        commit('setStickySessionId', stickySessionId);
    },
    async getUserShops({ dispatch, commit, state }) {
        // TODO: We'd like to use getCurrentUserShops() here, but that doesn't work when viewing a shop from Support
        // Something gets set in the BE session in a way that causes the shops list to be mixed up between a given user's shops
        // and the actual running user's shops.
        const response = await trackRequestStatus(
            commit,
            'getUserShops',
            dispatch('requestIfIdle', ['getUser']).then(() => getShopsByUserId(state.user.userId))
        );
        commit('setShops', response?.data || []);
    },
    async saveUserPreferences(
        { commit, state: { user } },
        {
            firstName = user.firstName,
            lastName = user.lastName,
            emailAddress = user.emailAddress,
            loginName = user.loginName,
            selectedStylesheet = user.userPreference?.selectedStylesheet,
            languageCode = user.userPreference?.languageCode,
        }
    ) {
        const userPreferencesData = {
            firstName,
            lastName,
            emailAddress,
            loginName,
            selectedStylesheet,
            languageCode,
        };
        await saveUserPreferences({ ...userPreferencesData, userId: user.userId });
        commit('updateUserPreferences', userPreferencesData);
    },
    async saveCompanyPreferences({ commit }, companyData) {
        await saveCompanyPreferences(companyData);
        commit('updateCompanyPreferences', companyData);
    },
    async getFeatures({ commit }) {
        const response = await trackRequestStatus(commit, 'getFeatures', getFeatures());
        commit('setFeatures', response?.data || []);
    },
    async getFeaturesForStore({ state, commit, dispatch }) {
        const response = await trackRequestStatus(
            commit,
            'getFeaturesForStore',
            dispatch('requestIfIdle', ['getCurrentShop']).then(() => getFeaturesForStore(state.currentShop.homeStore.storeId))
        );
        commit('setFeaturesForStore', response?.data || []);
    },
    async getCurrentShop({ state, commit, dispatch, getters }) {
        const response = await trackRequestStatus(
            commit,
            'getCurrentShop',
            getters.selectedShopId
                ? getShopDetailsById(getters.selectedShopId, false, true)
                : // If we don't have a selected shop ID, it could mean we don't yet have the user (where the previous selection is stored).
                  // In that case, wait for the user request, then try again.
                  // If we _still_ don't have a selected shop ID (very unlikely), we'll default to the first shop in the user's shop list
                  dispatch('requestIfIdle', ['getUser'])
                      .then(() => {
                          if (getters.selectedShopId) return Promise.resolve(getters.selectedShopId);
                          return dispatch('requestIfIdle', ['getUserShops']).then(() => state.shops[0].id);
                      })
                      .then((shopId) => getShopDetailsById(shopId, false, true))
        );
        commit('setCurrentShop', response?.data);
    },
    async getShopRequests({ state, commit, dispatch }) {
        const response = await trackRequestStatus(
            commit,
            'getShopRequests',
            dispatch('requestIfIdle', ['getCurrentShop']).then(() => axios.get(fcoUrl(`/shop/${state.currentShop.id}/company/shopRequests`)))
        );
        commit('setShopRequests', response?.data || []);
    },
    async getCompany({ state, commit, dispatch }) {
        const response = await trackRequestStatus(
            commit,
            'getCompany',
            dispatch('requestIfIdle', ['getCurrentShop']).then(() => axios.get(fcoUrl(`/shop/${state.currentShop.id}/company`)))
        );
        commit('setCompany', response?.data || getDefaultCompany());
    },
    async getIsEarnbackEligible({ state, commit, dispatch }) {
        // check hasOwnProperty instead of value check because we save a value of null when isEarnbackEligible is not set
        if (hasStorageItem(StorageKey.IS_EARNBACK_ELIGIBLE)) {
            const isEarnbackEligible = getStorageItem(StorageKey.IS_EARNBACK_ELIGIBLE);
            commit('setIsEarnbackEligible', isEarnbackEligible);

            commit('updateRequestStatus', {
                name: 'getIsEarnbackEligible',
                status: requestStatus.SUCCESS,
                statusCode: 200,
                error: null,
            });
        } else {
            const response = await trackRequestStatus(
                commit,
                'getIsEarnbackEligible',
                dispatch('requestIfIdle', ['getCurrentShop']).then(() =>
                    axios.get(fcoUrl(`/admin/earnback/blocklist/status/${state.currentShop.accountNumber}`))
                )
            );
            const isEarnbackEligible = response?.data === undefined || response?.data === null ? false : !response.data;
            commit('setIsEarnbackEligible', isEarnbackEligible);
        }
    },
    async getTSM({ commit }) {
        const { data: tsm } = await trackRequestStatus(commit, 'getTSM', axios.get(fcoUrl('/current/tsm')));
        commit('setTSM', tsm);
    },
    async getAppData({ commit }) {
        const response = await trackRequestStatus(commit, 'getAppData', axios.get(fcoUrl('/current/static')));
        const data = response?.data || {};
        commit('setAppData', data);
        commit('usageTracking/setStaticData', data);
    },
    async getCMSData({ commit }) {
        const request = await trackRequestStatus(commit, 'getCMSData', axios.get(fcoUrl(`/current/dotcms?withToken=true`)));
        commit('setCMSData', request.data || getDefaultCmsData());
    },
    getUSStates({ commit }) {
        const url = fcoUrl('/constants?states=true');

        trackRequestStatus(commit, 'getUSStates', axios.get(url)).then((response) => {
            commit('setUSStates', response.data.states);
        });
    },
    getJobTitles({ commit }) {
        const url = fcoUrl('/constants?jobTitles=true');

        trackRequestStatus(commit, 'getJobTitles', axios.get(url)).then((response) => {
            commit('setJobTitles', response.data.jobTitles);
        });
    },
    getCustomerTypes({ commit }) {
        const url = fcoUrl('/constants?customerTypes=true');

        trackRequestStatus(commit, 'getCustomerTypes', axios.get(url)).then((response) => {
            commit('setCustomerTypes', response.data.customerTypes);
        });
    },
    async saveUserEmailAddress({ commit }, email) {
        await saveUserEmail(email);

        commit('setUserEmailAddress', email);
        commit('setUserEmailUpdateRequired', false);
    },
    async declineUserEmailUpdate({ commit }) {
        await declineUserEmailUpdate();
        commit('setUserEmailUpdateRequired', false);
    },
    async refetchUserCriticalData({ dispatch }) {
        await dispatch('requestAll', {
            requests: [
                'getUserShops',
                'getCurrentShop',
                'getFeatures',
                'getFeaturesForStore',
                'getCMSData',
                'getCompany',
                'getShopRequests',
                'getTSM',
                'getIsEarnbackEligible',
            ],
        });
    },
    async clearLocalUserData({ dispatch, commit }) {
        commit('setPasswordChangeRequired', false);
        commit('setTermsAcceptRequired', false);
        commit('setIsEarnbackEligible', null);
        commit('vehicleSelector/resetVehicleData');
        dispatch('partSelection/clearLookupDetails');
    },
    async masquerade({ dispatch, commit, state }, userId) {
        // While we transition from legacy to SPA architecture, we need to call the legacy masquerade endpoint to get a new session cookie
        const [authResponse] = await Promise.all([!isSPA ? Promise.resolve() : masquerade(userId), masqueradeLegacy(userId)]);

        const masqueradeRealUser = {
            auth: { ...state.auth },
            userId: state.user.userId,
        };

        if (isSPA) {
            commit('setAuthTokens', authResponse?.data);
        }

        await dispatch('getStickySessionId');
        commit('setIsEarnbackEligible', null);

        commit('setMasqueradeRealUser', masqueradeRealUser);

        // Set the current page so we can go back once the user stops masquerading
        const currentPage = isSPA ? window.location.hash.slice(1) : window.location.href;
        commit('setMasqueradeSource', currentPage);

        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade happens
        await dispatch('vehicleSelector/clearCurrentVehicle');

        dispatch('getUser');
        await requestSettled(() => state.requests.getUser);

        if (isSPA) {
            dispatch('clearLocalUserData');
            await dispatch('refetchUserCriticalData');
        }
    },
    async stopMasquerade({ state, commit, dispatch }) {
        // While we transition from legacy to SPA architecture, we need to call the legacy masquerade endpoint to get a new session cookie
        await Promise.all([!isSPA ? Promise.resolve() : stopMasquerade(), stopMasqueradeLegacy()]);

        if (isSPA) {
            commit('setAuthTokens', { ...state.masqueradeRealUser.auth });
        }

        await dispatch('getStickySessionId');
        commit('setIsEarnbackEligible', null);

        // need to make sure FE vehicle data persistence clears out any selected vehicles anytime masquerade ends
        await dispatch('vehicleSelector/clearCurrentVehicle');

        dispatch('getUser');
        await requestSettled(() => state.requests.getUser);

        commit('setMasqueradeRealUser', null);

        if (isSPA) {
            dispatch('clearLocalUserData');
            await dispatch('refetchUserCriticalData');
        }
    },
    async selectShop({ commit, dispatch }, selectedShopId) {
        await selectShop(selectedShopId);
        await dispatch('getStickySessionId');
        commit('updateUserPreferences', { selectedShopId });
        commit('setIsEarnbackEligible', null);
        await dispatch('requestAll', {
            requests: ['getCurrentShop', 'getFeaturesForStore', 'getCompany', 'getTSM', 'getIsEarnbackEligible'],
        });
    },
};

const cxmlCustomerTypes = new Set([
    'JDBYRIDER',
    'COKE',
    'SONIC',
    'WASTEMANAGEMENT',
    'SSA',
    'CARVANA',
    'TEAMCARCARE',
    'BIGBRANDTIRE',
    'VIPSHOPMANAGEMENT',
    'DRIVE',
    'CARMAX',
]);

const getters = {
    isAuthExpired: ({ auth }) => !auth || auth?.accessExpiresOn < Date.now(),
    isRefreshExpired: ({ auth }) => !auth || auth?.refreshExpiresOn < Date.now(),
    isMasquerade: ({ masqueradeRealUser, user }) => {
        if (!masqueradeRealUser || !user) return false;
        if (!isSPA) return user.masquerade;
        return masqueradeRealUser.userId !== user?.userId;
    },
    // Unless the user is masquerading, if password reset is required, user should not be allowed full access
    isAuthorizedUser: ({ user, isPasswordChangeRequired, isTermsAcceptRequired }, { isRefreshExpired, isMasquerade }) => {
        if (!isSPA) return user.authenticated && (!user.passwordChangeRequired || user?.masquerade);
        return !isRefreshExpired && Boolean(user) && ((!isPasswordChangeRequired && !isTermsAcceptRequired) || isMasquerade);
    },
    isTeamMember: ({ user }, { isAuthorizedUser }) => {
        if (!isAuthorizedUser) return false;
        if (!isSPA) return user.teamMember;
        return user.group.internal;
    },
    isWebUser: ({ user }) => !user.customerType?.name || user.customerType?.name === 'WEB',
    isMitchell1User: ({ user }) => user.customerType?.name === 'MITCHELL',
    isROWriterUser: ({ user }) => user.customerType?.name === 'ROWRITER',
    isSmartEquipUser: ({ user }) => user.customerType?.name === 'SMARTEQUIP',
    isCXMLUser: ({ user }) => cxmlCustomerTypes.has(user.customerType?.name),
    isSMSUser: ({ user }) => Boolean(isSPA ? user?.customerType?.sms : user.customerTypeSms),
    isDemo: ({ company }) => Boolean(company?.demo),
    selectedShopId: ({ user }) => user?.userPreference?.selectedShopId,
    isLaborHidden: (_, { isROWriterUser }) => isROWriterUser,
    // All TMs default to store 4031. We need to hide this store's phone number from them so the store doesn't get overwhelmed with calls from TMs logging in with their accounts.
    // see: RWD-2327
    isStorePhoneHidden: ({ currentShop }, { isTeamMember }) => currentShop?.homeStore?.storeId === 4031 && isTeamMember,
    isPartsPayoffVisible: ({ company }, { userCanAccessSupport, userHasAnyRole }) => {
        if (userCanAccessSupport) return true;
        return company?.partsPayoffVisible && userHasAnyRole(Role.COMPANY_MANAGER, Role.SHOP_MANAGER);
    },
    isShopReferralEligible: ({ company }, { userCanAccessSupport, userHasAnyRole }) => {
        if (userCanAccessSupport) return true;
        return company?.shopReferralEligible && userHasAnyRole(Role.COMPANY_MANAGER, Role.SHOP_MANAGER);
    },
    userHasAnyRole:
        ({ user }) =>
        (...roles) => {
            const roleName = isSPA ? user?.group?.name : user?.group;
            return Boolean(roleName) && roles.some((role) => roleName === role);
        },
    userCanAccessSupport: (state, { userHasAnyRole }) => userHasAnyRole(Role.SUPERUSER, Role.SYSADMIN, Role.INSTALLER_SUPPORT, Role.MARKETING),
    userCanEditCompany: (state, { isSMSUser, userHasAnyRole }) =>
        !isSMSUser && userHasAnyRole(Role.SUPERUSER, Role.INSTALLER_SUPPORT, Role.COMPANY_MANAGER, Role.DEMO_USER),
    userCanViewCompanyDashboard: (state, { isSMSUser, userHasAnyRole }) =>
        !isSMSUser && userHasAnyRole(Role.SUPERUSER, Role.INSTALLER_SUPPORT, Role.COMPANY_MANAGER, Role.SHOP_MANAGER, Role.DEMO_USER),
    userCanEditShop: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER, Role.MARKETING)),
    userCanViewStatements: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewLaborClaims: (state, { userHasAnyRole }) =>
        userHasAnyRole(
            Role.SUPERUSER,
            Role.SYSADMIN,
            Role.INSTALLER_SUPPORT,
            Role.COMPANY_MANAGER,
            Role.SHOP_MANAGER,
            Role.MARKETING,
            Role.OREILLY_EMPLOYEE,
            Role.DEMO_USER,
            Role.SMS
        ),
    userCanViewActivityLog: (state, { isSMSUser, userCanEditCompany, userHasAnyRole }) =>
        !isSMSUser && (userCanEditCompany || userHasAnyRole(Role.SYSADMIN, Role.SHOP_MANAGER)),
    featuresByKey: ({ features }) =>
        features.reduce((featuresByKey, feature) => {
            featuresByKey[feature.descriptor] = feature;
            return featuresByKey;
        }, {}),
    featuresForStoreByKey: ({ featuresForStore }) =>
        featuresForStore.reduce((featuresForStoreByKey, feature) => {
            featuresForStoreByKey[feature.descriptor] = feature;
            return featuresForStoreByKey;
        }, {}),
    isFeatureDisabled:
        ({ features, featuresForStore }, { featuresByKey, featuresForStoreByKey, isAuthorizedUser }) =>
        (descriptor) => {
            const isGlobalFlagDisabled = !features.length || featuresByKey[descriptor]?.enabled === false;
            const isStoreFlagDisabled = !featuresForStore.length || featuresForStoreByKey[descriptor]?.enabled === false;
            if (isAuthorizedUser) return isGlobalFlagDisabled || isStoreFlagDisabled;
            return isGlobalFlagDisabled;
        },
    userDefaultLandingPage: (
        { currentShop },
        { isAuthorizedUser, userHasAnyRole, isMitchell1User, isROWriterUser, isSmartEquipUser, isCXMLUser }
    ) => {
        if (!isAuthorizedUser) {
            return !isSPA ? '/login.html' : '/login';
        }
        if (userHasAnyRole(Role.INSTALLER_SUPPORT, Role.MARKETING)) {
            return !isSPA ? '/admin/support.html' : '/support';
        }
        if (currentShop?.carCustomer) {
            return !isSPA ? '/carLanding.html' : '/car-admin';
        }
        if (isMitchell1User || isROWriterUser || isSmartEquipUser || isCXMLUser) {
            return !isSPA ? '/catalog/browse.html' : '/catalog/browse';
        }
        return '/';
    },
    namespacedRequests(state) {
        const namespacedRequests = {};
        Object.keys(state.requests).forEach((requestName) => {
            namespacedRequests[requestName] = {
                requestName,
                namespace: null,
                request: state.requests[requestName],
            };
        });
        Object.keys(state)
            .filter((stateKey) => isPlainObject(state[stateKey]) && Object.prototype.hasOwnProperty.call(state[stateKey], 'requests'))
            .forEach((namespace) => {
                const { requests } = state[namespace];
                if (!requests) return [];
                Object.keys(requests).forEach((requestName) => {
                    namespacedRequests[`${namespace}/${requestName}`] = {
                        requestName,
                        namespace,
                        request: requests[requestName],
                    };
                });
            });

        return namespacedRequests;
    },
};

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        vehicleSelector,
        miniQuote,
        stockOrderQuickAdd,
        opp,
        kits,
        partSelection,
        usageTracking,
    },
});

window.addEventListener('storage', ({ key }) => {
    if (key !== StorageKey.AUTH) return;

    const currentAuth = store.state.auth;
    const updatedAuth = getStorageItem(StorageKey.AUTH);

    // Prevent committing the same value to Vuex
    if (JSON.stringify(currentAuth) === JSON.stringify(updatedAuth)) return;

    store.commit('setAuthTokens', getStorageItem(key));
    store.dispatch('startRefreshTimer');
});

const hasCacheItem = (cacheKey) => Object.prototype.hasOwnProperty.call(requestCacheTime, cacheKey);

const getCacheItemLastRefresh = (cacheKey) => {
    const cacheRefreshKey = getCacheRefreshKey(cacheKey);
    if (!hasCacheItem(cacheRefreshKey)) return 0;
    return Number(requestCacheTime[cacheRefreshKey]) || 0;
};

const getCacheItemExpiration = (cacheKey) => {
    const cacheExpiresInMs = cacheExpirationByKey[cacheKey];
    const cacheRefreshedOn = getCacheItemLastRefresh(cacheKey);
    if (!cacheExpiresInMs || !cacheRefreshedOn) return 0;
    return cacheRefreshedOn + cacheExpiresInMs;
};

const isCachedItemExpired = (cacheKey) => {
    const expiresOn = getCacheItemExpiration(cacheKey);
    if (cacheExpirationByKey[cacheKey] && !expiresOn) return true;
    return Boolean(expiresOn && Date.now() >= expiresOn);
};

const removeCacheItem = (cacheKey) => {
    const cacheRefreshKey = getCacheRefreshKey(cacheKey);
    delete requestCacheTime[cacheRefreshKey];
};

const pendingPollKeys = new Set();

if (state.isSPA) {
    setInterval(async () => {
        const { namespacedRequests } = store.getters;
        const pollRequests = [];
        const pollRequestIndexByPollKey = {};

        Object.keys(namespacedRequests).forEach((name) => {
            const {
                request: { pollKey: key, pollValue, storageKey, status, cacheKey },
            } = namespacedRequests[name];

            const value = pollValue && pollValue(store);
            const isPollRequest = Boolean(key && value);

            if (!isPollRequest) return;

            const isPollRequestPending = isRequestPending({ status }) || pendingPollKeys.has(key);

            if (storageKey) {
                if (!isStorageItemExpired(storageKey) || isPollRequestPending) return;
            } else if (cacheKey) {
                if (!isCachedItemExpired(cacheKey) || isPollRequestPending) return;
            }

            pollRequestIndexByPollKey[key] = pollRequests.push({ name, key, value, storageKey, cacheKey }) - 1;
            pendingPollKeys.add(key);
        });

        if (!pollRequests.length) return;

        const { data } = await axios.get(fcoUrl('/meta'), { params: Object.fromEntries(pollRequests.map(({ key, value }) => [key, value])) });
        const requestsToRefresh = [];

        data.forEach(({ lastModified, entity, status }) => {
            pendingPollKeys.delete(entity);
            const isValidEntity = Object.prototype.hasOwnProperty.call(pollRequestIndexByPollKey, entity);
            if (status !== 'OK' || !isValidEntity) return;

            const modifiedDateTime = new Date(lastModified).getTime();

            let curStorageKey = null;
            let curCacheKey = null;

            if (pollRequests[pollRequestIndexByPollKey[entity]].storageKey) {
                curStorageKey = pollRequests[pollRequestIndexByPollKey[entity]].storageKey;
            } else if (pollRequests[pollRequestIndexByPollKey[entity]].cacheKey) {
                curCacheKey = pollRequests[pollRequestIndexByPollKey[entity]].cacheKey;
            }

            let lastRefreshDateTime = null;

            if (curStorageKey) {
                lastRefreshDateTime = getStorageItemLastRefresh(curStorageKey);
            } else if (curCacheKey) {
                const cacheTimeKey = getCacheRefreshKey(curCacheKey);
                lastRefreshDateTime = requestCacheTime[cacheTimeKey] || Date.now();
            }

            if (modifiedDateTime < lastRefreshDateTime) {
                if (curStorageKey) {
                    setStorageItemLastRefresh(curStorageKey, Date.now());
                } else {
                    store.commit('setCachedItemLastRefresh', { cacheKey: curCacheKey, refreshedOn: Date.now() });
                }
                return;
            }

            const { name, storageKey, cacheKey } = pollRequests[pollRequestIndexByPollKey[entity]];
            requestsToRefresh.push(name);

            if (storageKey) removeStorageItem(storageKey);
            if (cacheKey) removeCacheItem(cacheKey);
        });

        if (requestsToRefresh.length) {
            store.dispatch('requestAll', { requests: requestsToRefresh });
        }
    }, secondsToMs(30));
}

export default store;
