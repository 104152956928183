var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "form",
    {
      attrs: {
        id: "vinNumber",
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleVinSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "label",
        {
          attrs: {
            for: "vin",
          },
        },
        [
          _vm._v(
            _vm._s(_vm.fcoM("rs.homeMain.enterVin", "Enter Vin Number") || " ")
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "input-group",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            class: {
              error: _vm.validationError,
            },
            attrs: {
              type: "text",
              id: "vin",
              minlength: "5",
              maxlength: "17",
              "data-qa": "vin-input",
              placeholder: _vm.fcoM(
                "rs.homeMain.enterVinPlaceholder",
                "i.e.19UYA31581L000000"
              ),
            },
            domProps: {
              value: _vm.inputValue,
            },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.inputValue = $event.target.value
                },
                _vm.filterInput,
              ],
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "gtm-vin-search input-group-append button px-2 px-xl-3 py-1 mb-0",
              attrs: {
                "data-qa": "vin-submit-button",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "d-sm-none d-lg-inline",
                },
                [_vm._v(_vm._s(_vm.fcoM("common.go", "Go") || " "))]
              ),
              _vm._v(" "),
              _c("Icon", {
                attrs: {
                  name: "chevron-right",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.validationError
        ? _c(
            "span",
            {
              staticClass: "error",
            },
            [_vm._v(_vm._s(_vm.validationErrorMsg))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showNewQuotePrompt
        ? _c("QuoteSelectionModal", {
            on: {
              close: function ($event) {
                _vm.showNewQuotePrompt = false
              },
              "start-new-quote": function ($event) {
                return _vm.handleQuotePromptAnswer(false)
              },
              "use-existing-quote": function ($event) {
                return _vm.handleQuotePromptAnswer(true)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }