/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
import axios from 'axios';
import { fcoUrl } from 'fcoModules/utilities';
import { CacheKey, getCacheRefreshKey, requestCacheTime } from '@/common/store/store-utils';
import * as quoteService from '../../services/quoteService';
import { createRequestStatus, trackRequestStatus, updateRequestStatus } from '../request-status';

const getDefaultQuoteData = () => ({
    subTotalCost: 0,
    subTotalPrice: 0,
    totalItems: 0,
    worksheetHeaderId: null,
});

export const state = {
    expandMiniQuote: false,
    quoteProducts: [],
    quoteDetails: getDefaultQuoteData(),
    requests: {
        getQuoteData: createRequestStatus({
            cacheKey: CacheKey.WORKSHEET,
            pollKey: 'worksheet',
            pollValue: ({ state }) => state.miniQuote?.quoteDetails?.worksheetHeaderId,
        }),
    },
};

export const mutations = {
    updateRequestStatus,
    setExpandMiniQuote(state, val) {
        state.expandMiniQuote = val;
    },
    setQuoteData(state, { quoteDetails, subtotalCost, subtotalPrice, totalItems, worksheetHeaderId }) {
        state.quoteProducts = quoteDetails;
        state.quoteDetails = {
            subtotalCost,
            subtotalPrice,
            totalItems,
            worksheetHeaderId,
        };
        requestCacheTime[getCacheRefreshKey(CacheKey.WORKSHEET)] = new Date();
    },
};

export const actions = {
    openMiniQuote({ commit }) {
        commit('setExpandMiniQuote', true);
    },
    closeMiniQuote({ commit }) {
        commit('setExpandMiniQuote', false);
    },
    async getQuoteData({ commit }) {
        const response = await trackRequestStatus(commit, 'getQuoteData', quoteService.getMiniQuote());
        commit('setQuoteData', response?.data ?? getDefaultQuoteData());
    },
    clearQuote({ commit }) {
        commit('setQuoteData', getDefaultQuoteData());
    },
    async addProduct({ dispatch }, { product, quantity }) {
        const { data } = await quoteService.addProduct(product, quantity);
        // The addproduct response does not include updated quote info (like totals or discounts), so we need to get the latest
        await dispatch('getQuoteData');
        return data;
    },
    async addKitProducts({ dispatch }, { products, isPrebuilt }) {
        const { data } = await quoteService.addKitProducts(products, isPrebuilt);
        // The addkit response does not include updated quote info (like totals or discounts), so we need to get the latest
        await dispatch('getQuoteData');
        return data;
    },
    async newQuote({ dispatch }) {
        await axios.post(fcoUrl('/vehicle/select/default'), { id: 0 });
        await dispatch('getQuoteData');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
