<template>
    <div class="overflow-auto">
        <div v-if="isLoading" class="d-flex justify-content-center mt-3">
            <FcoSpinner />
        </div>
        <div v-else>
            <!-- The perPage is set to recentVehicles.length + 1 to make sure we always have at least one page and no pagination -->
            <FcoTable
                :tableClass="{ selectable: hasVehicles }"
                :tbodyClass="{ 'font-italic': !hasVehicles }"
                :data="recentVehicles"
                @click-row="handleVehicleClick($event.id)"
                :perPage="recentVehicles.length + 1"
                :noResultsText="fcoM('rs.homeMain.noVehicles', 'You have no recent vehicles.')"
            >
                <!-- Date -->
                <FcoTableColumn v-if="$fcoMq.isSmUp" prop="createDateTime" :label="fcoM('common.date', 'Date')" :sortable="false" />

                <!-- Vin -->
                <FcoTableColumn v-if="$fcoMq.isMdUp" class="text-nowrap" :label="fcoM('common.vin', 'VIN')">
                    <template #td="recentVehicle">
                        <span v-if="recentVehicle.vin" class="text-uppercase">{{ recentVehicle.vin }}</span>
                        <span v-else>{{ fcoM('common.na', 'N/A') }}</span>
                        <template v-if="recentVehicle.sourceVinScanner">
                            <Icon class="ml-1 text-lg" name="mobile" />
                            <span
                                v-if="recentVehicle.vehicleNotes && recentVehicle.vehicleNotes !== ''"
                                v-fco-tooltip="{ content: recentVehicle.vehicleNotes, container: $el }"
                            >
                                <Icon name="pencil-square-o" class="ml-1 clickable text-lg" />
                            </span>
                        </template>
                    </template>
                </FcoTableColumn>

                <!-- License Plate -->
                <FcoTableColumn v-if="$fcoMq.isMdUp" :label="fcoM('common.vehicleLicensePlate', 'License Plate #')">
                    <template #td="recentVehicle">
                        <span v-if="recentVehicle.licensePlate !== ''">{{ recentVehicle.licensePlate }}</span>
                        <span v-else>{{ fcoM('common.na', 'N/A') }}</span>
                    </template>
                </FcoTableColumn>

                <!-- Vehicle -->
                <FcoTableColumn class="text-nowrap" :label="fcoM('common.vehicle', 'Vehicle')">
                    <template #td="recentVehicle">
                        {{ recentVehicle.epcVehicleYear }} {{ recentVehicle.epcVehicleMake }} {{ recentVehicle.epcVehicleModel }}
                    </template>
                </FcoTableColumn>

                <!-- Delete Recent Vehicle -->
                <FcoTableColumn tdClass="delete-vehicle center">
                    <template #td="recentVehicle">
                        <!-- Need to put the @click event on a div wrapper or else the event doesn't fire properly -->
                        <div @click.stop="deleteVehicle(recentVehicle.id)">
                            <Icon name="times" class="clickable red" />
                        </div>
                    </template>
                </FcoTableColumn>
            </FcoTable>
        </div>
        <QuoteSelectionModal
            v-if="showNewQuotePrompt"
            @close="showNewQuotePrompt = false"
            @start-new-quote="handleQuotePromptAnswer(false)"
            @use-existing-quote="handleQuotePromptAnswer(true)"
        />
    </div>
</template>

<script>
import axios from 'axios';
import { fcoTooltip } from 'fco/src/vue/directives/tooltip';
import vsMixin from '@/common/mixins/vsMixin';
import { FcoTable, FcoTableColumn } from 'fco/src/vue/components/table';
import Icon from './Icon.vue';
import QuoteSelectionModal from './vehicleSelector/QuoteSelectionModal.vue';

export default {
    name: 'RecentVehicles',
    mixins: [vsMixin],
    data() {
        return {
            isLoading: false,
            tempVehicle: null,
            showNewQuotePrompt: false,
        };
    },
    computed: {
        recentVehicles() {
            return this.$store.state.vehicleSelector.recentVehicles;
        },
        hasVehicles() {
            return !this.isLoading && this.recentVehicles.length > 0;
        },
    },
    directives: { fcoTooltip },
    components: {
        Icon,
        FcoTable,
        FcoTableColumn,
        QuoteSelectionModal,
    },
    methods: {
        async deleteVehicle(vehicleId) {
            try {
                await this.$store.dispatch('vehicleSelector/deleteRecentVehicle', vehicleId);
            } catch {
                this.$fcoToast.error(this.fcoM('rs.homeMain.errorRemovingVehicle', 'There was an error removing the vehicle, please try again later'));
            }
        },
        handleQuotePromptAnswer(keepExistingQuote) {
            this.userWantsExistingQuote = keepExistingQuote;
            this.showNewQuotePrompt = false;
            this.handleVehicleClick(this.tempVehicle, true);
        },
        handleVehicleClick(vehicleId, ignoreQuoteCheck = false) {
            if (this.productsInCurrentQuote && this.promptAboutQuote && !ignoreQuoteCheck) {
                this.showNewQuotePrompt = true;
                this.tempVehicle = vehicleId;
                return;
            }

            this.selectVehicle(vehicleId);
        },
        async selectVehicle(vehicleId) {
            this.isLoading = true;
            try {
                const {
                    data: { vehicle },
                } = await axios.post(this.fcoUrl(`/vehicle/select/history/${vehicleId}/select?quote=${this.keepExistingQuoteFinal}`));
                this.handleVehicleSelectSuccess({ gaEventAction: 'recentVehicle', vehicle });
                if (this.preventNavigationOnVehicleUpdate) {
                    this.isLoading = false;
                }
            } catch (error) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.errorLoadingVehicle', 'There was an error loading the vehicle, please try again later'));
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.$store.dispatch('vehicleSelector/getRecentVehicles');
        } catch {
            this.$fcoToast.error(this.fcoM('rs.homeMain.errorLoadingRecentVehicles', 'There was an error loading recent vehicles, please try again later'));
        }
        this.isLoading = false;
    },
};
</script>

<style lang="scss">
.delete-vehicle {
    width: 5%;
}
</style>
