<template>
    <div id="ymm" class="ymm-input-cursor-wraper">
        <div class="d-flex justify-content-between flex-wrap">
            <label for="yearSelect">{{ fcoM('rs.homeMain.enterYMM', 'Enter Year, Make, Model') || '&nbsp;' }}</label>
            <a
                href="javascript:void(0)"
                @click="showRecentVehicles"
                class="recent-vehicles-link gtm-recent-vehicles-link"
                id="recentVehicleLink"
                data-qa="ymm-recent-vehicles-link"
                >{{ fcoM('rs.homeMain.recentVehicles', 'Recent Vehicles') || '&nbsp;' }}</a
            >
        </div>
        <div class="position-relative mt-2">
            <span class="bg-white custom-legend">{{ fcoM('common.year', 'Year') }}</span>
            <fco-search-select
                :value="yearSelected"
                class="ymm-input d-none d-sm-block"
                :allow-empty="false"
                :placeholder="tempYearSelected.toString() || fcoM('rs.homeMain.selectYear', 'Select Year')"
                :grid="true"
                :grid-rows="14"
                :options="vsData.years"
                :menu-class="['ymm-results']"
                mode="fuzzy"
                :searchKeys="['label']"
                name="yearSelect"
                ref="yearSelect"
                data-qa="ymm-year-select"
                @focus="handleFocus(Input.YEAR)"
                @blur="handleBlur(Input.YEAR)"
                @select="handleYearSelect"
            />
            <select class="custom-select d-sm-none" @change="handleYearSelect({ value: $event.target.value })" data-qa="ymm-mobile-year-select">
                <option value="" disabled selected hidden>{{ fcoM('rs.homeMain.selectYear', 'Select Year') }}</option>
                <option v-for="year in vsData.years" :value="year" :key="year">{{ year }}</option>
            </select>
        </div>

        <div v-show="yearSelected" class="position-relative mt-2">
            <span class="bg-white custom-legend">{{ fcoM('rs.pdp.make', 'Make') }}</span>
            <fco-search-select
                :class="['ymm-input', 'd-none', { 'd-sm-block': yearSelected }]"
                :value="makeSelected.id"
                :allow-empty="false"
                :placeholder="tempMakeSelected.value || fcoM('rs.homeMain.selectMake', 'Select Make')"
                :grid="true"
                :grid-rows="14"
                :options="makes"
                :mapOption="(option) => ({ label: option.value, value: option.id })"
                :menu-class="['ymm-results']"
                ref="makeSelect"
                data-qa="ymm-make-select"
                @focus="handleFocus(Input.MAKE)"
                @blur="handleBlur(Input.MAKE)"
                @select="handleMakeSelect"
            />
            <select class="custom-select d-sm-none" @change="handleMakeSelect({ id: $event.target.value })" data-qa="ymm-mobile-make-select">
                <option value="" disabled selected hidden>{{ fcoM('rs.homeMain.selectMake', 'Select Make') }}</option>
                <option v-for="make in makes" :value="make.id" :key="make.value">{{ make.value }}</option>
            </select>
        </div>

        <div v-show="makeSelected" class="position-relative mt-2">
            <span class="bg-white custom-legend">{{ fcoM('vehicle.model', 'Model') }}</span>
            <fco-search-select
                :class="['ymm-input', 'd-none', { 'd-sm-block': makeSelected }]"
                :value="modelSelected.id"
                :allow-empty="false"
                :placeholder="tempModelSelected.value || fcoM('rs.homeMain.selectModel', 'Select Model')"
                :grid="true"
                :grid-rows="14"
                :options="models"
                :mapOption="(option) => ({ label: option.model.value, value: option.model.id })"
                :menu-class="['ymm-results']"
                ref="modelSelect"
                data-qa="ymm-model-select"
                @focus="handleFocus(Input.MODEL)"
                @blur="handleBlur(Input.MODEL)"
                @select="handleModelSelect"
            />
            <select class="custom-select d-sm-none" @change="handleModelSelect(JSON.parse($event.target.value))" data-qa="ymm-mobile-model-select">
                <option value="" disabled selected hidden>{{ fcoM('rs.homeMain.selectModel', 'Select Model') }}</option>
                <option v-for="model in models" :value="JSON.stringify(model)" :key="model.model.id">{{ model.model.value }}</option>
            </select>
        </div>

        <div v-show="modelSelected" class="position-relative mt-2">
            <span class="bg-white custom-legend">{{ fcoM('attributes.engine', 'Engine') }}</span>
            <fco-search-select
                :class="['ymm-input', 'd-none', { 'd-sm-block': modelSelected }]"
                :value="engineSelected.id"
                :allow-empty="false"
                :placeholder="tempEngineSelected.value || `${fcoM('rs.homeMain.selectEngine', 'Select Engine')} (${fcoM('common.optional', 'Optional')})`"
                :grid="true"
                :grid-rows="14"
                :options="engines"
                :mapOption="(option) => ({ label: option.value, value: option.id })"
                :menu-class="['ymm-results']"
                ref="engineSelect"
                data-qa="ymm-engine-select"
                @focus="handleFocus(Input.ENGINE)"
                @blur="handleBlur(Input.ENGINE)"
                @select="handleEngineSelect"
            />
            <select class="custom-select d-sm-none" @change="handleEngineSelect(JSON.parse($event.target.value))" data-qa="ymm-mobile-engine-select">
                <option value="" disabled selected hidden>{{ fcoM('rs.homeMain.selectEngine', 'Select Engine') }}</option>
                <option v-for="engine in engines" :value="JSON.stringify(engine)" :key="engine.id">{{ engine.value }}</option>
            </select>
        </div>

        <div v-show="engineSelected" class="position-relative mt-2">
            <span class="bg-white custom-legend">{{ fcoM('attributes.submodel', 'Submodel') }}</span>
            <fco-search-select
                :class="['ymm-input', 'd-none', { 'd-sm-block': engineSelected }]"
                :value="submodelSelected.id"
                :allow-empty="false"
                :placeholder="tempSubmodelSelected.value || `${fcoM('rs.homeMain.selectSubmodel', 'Select Submodel')} (${fcoM('common.optional', 'Optional')})`"
                :grid="true"
                :grid-rows="14"
                :options="submodels"
                :mapOption="(option) => ({ label: option.value, value: option.id })"
                :menu-class="['ymm-results']"
                ref="submodelSelect"
                data-qa="ymm-submodel-select"
                @focus="handleFocus(Input.SUBMODEL)"
                @blur="handleBlur(Input.SUBMODEL)"
                @select="handleSubmodelSelect"
            />
            <select
                class="custom-select d-sm-none"
                @change="handleSubmodelSelect(JSON.parse($event.target.value))"
                data-qa="ymm-mobile-submodel-select"
            >
                <option value="" disabled selected hidden>{{ fcoM('rs.homeMain.selectSubmodel', 'Select Submodel') }}</option>
                <option v-for="submodel in submodels" :value="JSON.stringify(submodel)" :key="submodel.id">{{ submodel.value }}</option>
            </select>
        </div>

        <button v-show="modelSelected" @click.prevent="handleAddVehicleSubmit(false)" class="button mt-3">{{ fcoM('rs.infoBar.addVehicle', 'Add Vehicle') }}</button>

        <QuoteSelectionModal
            v-if="showNewQuotePrompt"
            @close="showNewQuotePrompt = false"
            @start-new-quote="handleQuotePromptAnswer(false)"
            @use-existing-quote="handleQuotePromptAnswer(true)"
        />
    </div>
</template>

<script>
import { FcoSearchSelect } from 'fco/src/vue/components/searchselect';
import axios from 'axios';
import { mapState } from 'vuex';
import { isRequestIdle } from '@/common/store/request-status';
import QuoteSelectionModal from './QuoteSelectionModal.vue';
import vsMixin from '../../mixins/vsMixin';
import { stringFormat } from '../../../fcoModules/utilities';

const inputRefs = ['yearSelect', 'makeSelect', 'modelSelect', 'engineSelect', 'submodelSelect'];
const Input = {
    YEAR: 'year',
    MAKE: 'make',
    MODEL: 'model',
    ENGINE: 'engine',
    SUBMODEL: 'submodel',
};

export default {
    name: 'YearMakeModel',
    mixins: [vsMixin],
    data() {
        return {
            baseVehicleId: '', // this is the baseVehicleId during the YMM process
            yearSelected: '',
            makeSelected: '',
            modelSelected: '',
            engineSelected: '',
            submodelSelected: '',
            tempYearSelected: '',
            tempMakeSelected: '',
            tempModelSelected: '',
            tempEngineSelected: '',
            tempSubmodelSelected: '',
            makes: [],
            models: [],
            engines: [],
            submodels: [],
            Input,
            showNewQuotePrompt: false,
        };
    },
    computed: {
        ...mapState('vehicleSelector', {
            vehicleRequest: (state) => state.requests.getCurrentVehicle,
            currentVehicle: (state) => state.currentVehicle,
        }),
        isYearSelectorOpen() {
            return this.$store.state.vehicleSelector.isYearSelectorOpen;
        },
        hasRequestedVSData() {
            return !this.isVSDataIdle && !this.isVSDataPending;
        },
        isYearSelectReadyToOpen() {
            return this.isYearSelectorOpen && this.hasRequestedVSData;
        },
    },
    components: { FcoSearchSelect, QuoteSelectionModal },
    watch: {
        async isYearSelectReadyToOpen(isReady) {
            if (!isReady) return;

            await this.$nextTick();

            const { activeElement } = document;
            const isYmmInputFocused = inputRefs.some((ref) => this.$refs[ref].$el.contains(activeElement));

            // If none of our inputs have focus, that means isYearSelectorOpen was changed from the outside, and we should open the year selector
            if (!isYmmInputFocused) {
                this.$refs.yearSelect.focus();
            }
        },
    },
    methods: {
        handleFocus(inputType) {
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: true });
            const tempValue = `temp${inputType.charAt(0).toUpperCase() + inputType.slice(1)}Selected`;
            const value = `${inputType}Selected`;
            this[tempValue] = this[value];
            this[value] = '';
        },
        handleBlur(inputType) {
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: false });
            const tempValue = `temp${inputType.charAt(0).toUpperCase() + inputType.slice(1)}Selected`;
            const value = `${inputType}Selected`;

            if (!this[value]) {
                this[value] = this[tempValue];
            }
            this[tempValue] = '';
        },
        handleQuotePromptAnswer(keepExistingQuote) {
            this.userWantsExistingQuote = keepExistingQuote;
            this.showNewQuotePrompt = false;
            this.handleAddVehicleSubmit(true);
        },
        async handleYearSelect({ value }) {
            this.makeSelected = '';
            this.modelSelected = '';
            this.engineSelected = '';
            this.submodelSelected = '';
            this.baseVehicleId = '';

            try {
                const { data } = await axios.get(this.fcoUrl(`/vehicle/select/v2/makes?year=${value}`));

                this.yearSelected = value;
                this.makes = data;

                await this.$nextTick();
                this.$refs.makeSelect.focus();
            } catch (err) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.vsGenericError', 'Error getting data, please try again'));
            }
        },
        async handleMakeSelect(make) {
            this.modelSelected = '';
            this.engineSelected = '';
            this.submodelSelected = '';
            this.baseVehicleId = '';

            try {
                const { data } = await axios.get(this.fcoUrl(`/vehicle/select/v2/models?year=${this.yearSelected}&makeId=${make.id}`));

                this.makeSelected = make;
                this.models = data;

                await this.$nextTick();
                this.$refs.modelSelect.focus();
            } catch (err) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.vsGenericError', 'Error getting data, please try again'));
            }
        },
        async handleModelSelect({ model, baseVehicleId }) {
            this.engineSelected = '';
            this.submodelSelected = '';

            this.modelSelected = model;
            this.baseVehicleId = baseVehicleId;

            try {
                const { data } = await axios.get(this.fcoUrl(`/vehicle/select/engines?baseVehicleId=${baseVehicleId}`));
                this.engines = data;

                // if there is only one engine choice then automatically select it
                if (this.engines.length === 1) {
                    this.handleEngineSelect(this.engines[0]);

                    // need to clear temp data since auto select never triggers focus/blur methods which normally update this
                    this.tempEngineSelected = '';
                }
            } catch (err) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.vsGenericError', 'Error getting data, please try again'));
            }
        },
        async handleEngineSelect(engine) {
            this.submodelSelected = '';
            this.engineSelected = engine;

            try {
                const { data } = await axios.get(this.fcoUrl(`/vehicle/select/submodels?baseVehicleId=${this.baseVehicleId}&engineId=${engine.id}`));
                this.submodels = data;

                // If there is only one submodel option auto select it. "Don't Know Show All" is always an option so need to check for 2 choices here.
                if (this.submodels.length === 2) {
                    this.handleSubmodelSelect(this.submodels[0]);

                    // need to clear temp data since auto select never triggers focus/blur methods which normally update this
                    this.tempSubmodelSelected = '';
                }
            } catch (err) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.vsGenericError', 'Error getting data, please try again'));
            }
        },
        handleSubmodelSelect(submodel) {
            this.submodelSelected = submodel;
            this.handleAddVehicleSubmit();
        },
        handleAddVehicleSubmit(ignoreQuoteCheck = false) {
            if (this.productsInCurrentQuote && this.promptAboutQuote && !ignoreQuoteCheck) {
                this.showNewQuotePrompt = true;
                return;
            }
            this.selectVehicle();
        },
        async selectVehicle() {
            const loading = this.$fcoLoading(
                stringFormat(this.fcoM('rs.getparts.selectingYMMLoading', 'Selecting {0} {1} {2}...'), this.yearSelected, this.makeSelected.value, this.modelSelected.value)
            );
            try {
                const vehicleData = {
                    baseVehicleId: this.baseVehicleId,
                    quote: this.keepExistingQuoteFinal,
                };
                if (this.engineSelected) vehicleData.engineId = this.engineSelected.id;
                if (this.submodelSelected) vehicleData.submodelId = this.submodelSelected.id;
                const {
                    data: { vehicle },
                } = await axios.post(this.fcoUrl('/vehicle/select/v2/'), vehicleData);
                this.handleVehicleSelectSuccess({ gaEventAction: 'ymm', vehicle });
                if (this.preventNavigationOnVehicleUpdate || this.isSPA) {
                    loading.remove();
                }
            } catch (err) {
                this.$fcoToast.error(this.fcoM('rs.homeMain.vsGenericError', 'Error getting data, please try again'));
                loading.remove();
            }
        },
        showRecentVehicles() {
            /*
            Since the recent vehicles modal is in the Vehicle Selector component, we need
            make sure all of the select inputs are closed because they can appear over top
            of the modal.
            */
            inputRefs.forEach((ref) => this.$refs[ref].blur());

            this.$store.commit('vehicleSelector/setIsRecentVehiclesModalShown', true);
        },
    },
    mounted() {
        if (isRequestIdle(this.vehicleRequest)) this.$store.dispatch('vehicleSelector/getCurrentVehicle');
    },
};
</script>

<style lang="scss">
@import '~scssVariables/config';

.ymm-results {
    .search-result {
        font-size: 0.875rem;
        padding: 0.125rem 0.65rem;
        text-decoration: none;

        &.active {
            background-color: $green;
        }
    }
}

.ymm-input-cursor-wraper {
    .ymm-input {
        cursor: pointer;
        font-size: 0.875rem;

        .typeahead-input {
            background-image: none;
        }
    }
}

.custom-legend {
    position: absolute;
    left: 10px;
    top: -5px;
    z-index: 10;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0 0.125rem;
    line-height: 1;
}
</style>
