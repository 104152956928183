import store from '../store/store';

/*
Broke this out into separate file to avoid cyclical dependency
between plugins/fcoI18n.js and utilities/validator.js
*/
export const fcoM = (...args) => store.getters['i18n/fcoM'](...args);

export default {
    fcoM,
};
