/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
import axios from 'axios';
import { createRequestStatus, trackRequestStatus, updateRequestStatus } from '../request-status';
import { fcoUrl } from '../../../fcoModules/utilities';

export const state = {
    kitDetails: {},
    requests: {
        getKitDetails: createRequestStatus(),
    },
};
export const mutations = {
    updateRequestStatus,
    setKitDetails(state, kitDetails) {
        state.kitDetails = kitDetails;
    },
};
export const actions = {
    async getKitDetails({ commit }) {
        const { data: messages } = await trackRequestStatus(
            commit,
            'getKitDetails',
            axios.get(fcoUrl('/message/get/category/PRODUCT?ignoreLanguage=true'))
        );
        commit('setKitDetails', Object.fromEntries(messages.map(({ messageKey, content }) => [messageKey, content])));
    },
};
export const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
