/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
import axios from 'axios';
import { fcoUrl } from 'fcoModules/utilities';
import {
    getAttributeData,
    isProductOutOfStock,
    validatePartTypeId,
    getResultDetailsObj,
    getLocalQtyDetails,
    determineKitProductByAddQuoteResponse,
    buildResultsDetailsObj,
    getResultSummaryData,
    getResultDetailsData,
} from 'fcoModules/usageTrackingService';

export const state = {
    tokenParam: null,
    isDisabled: false,
    usageTrackingData: null,
    staticData: null,
};

export const getters = {
    getCurrentDate({ usageTrackingData }) {
        return usageTrackingData?.uuid?.timeStamp;
    },
    getCurrentUUID({ usageTrackingData }) {
        return usageTrackingData?.uuid?.uuid;
    },
    getCustomerId({ usageTrackingData }) {
        return usageTrackingData?.customer?.id;
    },
    getCustomerZipCode({ usageTrackingData }) {
        return usageTrackingData?.customer?.zipCode;
    },
    getCatalogVersion({ usageTrackingData }) {
        return usageTrackingData?.catalog?.ocatVersion;
    },
    getApplicationId({ staticData }) {
        return staticData?.application?.id;
    },
    getStoreId({ usageTrackingData }) {
        return usageTrackingData?.customer?.storeNumber;
    },
    getCatalogLookupType({ usageTrackingData }) {
        return usageTrackingData?.catalog?.lookupType;
    },
    getVehicleLookupType({ usageTrackingData }) {
        return usageTrackingData?.vehicle?.lookupType;
    },
    getVehicleBaseId({ usageTrackingData }) {
        return usageTrackingData?.vehicle?.baseId;
    },
    getVehicleId({ usageTrackingData }) {
        return usageTrackingData?.vehicle?.id;
    },
    getQuoteProducts({ usageTrackingData }) {
        return usageTrackingData?.catalog?.quoteProducts;
    },
    getLookups({ usageTrackingData }) {
        return usageTrackingData?.catalog?.lookups;
    },
    getVehicles(_, getters) {
        return (isFromQuote) => {
            if (isFromQuote) {
                return getters.getQuoteProducts?.vehicles;
            }

            return getters.getLookups?.vehicles;
        };
    },
    getAddPartsToCatalogURL({ usageTrackingData }) {
        return fcoUrl(usageTrackingData?.paths?.addPartsToCatalogLookups);
    },
    getCurrentVehicleProducts(_, getters) {
        return (isFromQuote) => {
            const { getVehicles, getVehicleId } = getters;
            const vehicle = getVehicles(isFromQuote)[getVehicleId];

            return vehicle?.products || {};
        };
    },
    lookupParentPartType({ usageTrackingData }) {
        return (partType) => (usageTrackingData?.catalog?.parentPartTypes[partType] ? usageTrackingData.catalog.parentPartTypes[partType] : '0');
    },
    getLocalQuantityOnHand(_, getters) {
        return (partId, vehicleId, isFromQuote) => {
            const tempVehicleId = vehicleId || getters.getVehicleId;
            const part = getters.getVehicleProducts(tempVehicleId, isFromQuote)[partId];

            if (part && part.localQuantityOnHand !== null) {
                return part.localQuantityOnHand;
            }

            return 0;
        };
    },
    getIsStockingItem(_, getters) {
        return (partId, vehicleId, isFromQuote) => {
            const tempVehicleId = vehicleId || getters.getVehicleId;
            const part = getters.getVehicleProducts(tempVehicleId, isFromQuote)[partId];

            if (part && part.stocking !== null) {
                return part.stocking;
            }

            return false;
        };
    },
    /**
     * Returns result summary lookup object that describes part types that were returned in lookup process
     * @param {string} catalogLookupType - optional overriding parameter for specifying a different lookup type
     * @returns {Array}
     */
    getLookupSummaryData({ usageTrackingData }, getters) {
        return (catalogLookupType) => {
            const data = usageTrackingData.catalog.selectedPartTypes;
            const results = [];
            let tempObj;
            let parentPartTypeId;

            if (usageTrackingData.catalog.lookupType === 'PART_NO') {
                results.push({
                    partTypeId: 0,
                    parentPartTypeId: 0,
                });
            } else if (catalogLookupType && catalogLookupType === 'REL_ITEM') {
                results.push({
                    partTypeId: 0,
                    parentPartTypeId: 0,
                    vehicleId: 0,
                    vehicleLookupType: null,
                    catalogLookupType,
                });
            } else {
                for (let i = 0; i < data.length; i++) {
                    parentPartTypeId = getters.lookupParentPartType(data[i].partTypeId);
                    tempObj = {
                        partTypeId: validatePartTypeId(data[i].partTypeId),
                        parentPartTypeId,
                    };

                    if (catalogLookupType) {
                        tempObj.catalogLookupType = catalogLookupType;
                    }

                    results.push(tempObj);
                }
            }

            return results;
        };
    },
    /**
     * Gets specific lookup details that describe how product was looked up
     * @param {boolean} isRelatedProduct - true if lookup details are being gathered for related product actions/lookups
     * @param {Array} filteredBrands - contains list of currently filtered brands
     * @returns {Array} lookup details objects with required data to be logged
     */
    getLookupDetailsData({ usageTrackingData }) {
        return (isRelatedProduct = false, filteredBrands = []) => {
            const results = [];
            const typeObj = {
                detailType: 'TYPE',
            };
            const data = usageTrackingData.catalog.selectedPartTypes;
            let i;

            if (usageTrackingData.vehicle.lookupType === 'YMM') {
                typeObj.detailValue = 'app';
            } else if (usageTrackingData.vehicle.lookupType === 'NULL') {
                typeObj.detailValue = 'all';
            } else if (usageTrackingData.vehicle.lookupType === 'VIN') {
                typeObj.detailValue = 'vin';
            }

            if (usageTrackingData.catalog.lookupType === 'PROMO') {
                results.push({
                    detailType: 'KIT',
                    detailValue: 'kit',
                });
            }

            // interchange overrides lookup type for this value
            if (usageTrackingData.catalog.lookupType === 'INTCHANGE') {
                typeObj.detailValue = 'ic';

                results.push({
                    detailType: 'PART_NO',
                    detailValue: usageTrackingData.catalog.searchQuery,
                });

                for (i = 0; i < data.length; i++) {
                    results.push({
                        detailType: 'SELECTED_PART_TYPE',
                        detailValue: data[i].partTypeId,
                    });

                    results.push({
                        detailType: 'PART_NAME',
                        detailValue: data[i].partTypeName,
                    });
                }

                if (usageTrackingData.catalog.selectedManufacturer) {
                    results.push({
                        detailType: 'MANUFACTURER',
                        detailValue: usageTrackingData.catalog.selectedManufacturer,
                    });

                    // Supposedly this is always to be hard coded to this value for RWD
                    // when selecting a mfg during interchange search
                    results.push({
                        detailType: 'LINES',
                        detailValue: 'All Lines',
                    });
                }
            }

            if (isRelatedProduct) {
                typeObj.detailValue = 'all';
            }

            // BRD says to always have at least 1 lookup detail with detailType being "TYPE"
            results.push(typeObj);

            if (!isRelatedProduct) {
                if (usageTrackingData.catalog.lookupType === 'PART_NO') {
                    results.push({
                        detailType: 'PART_NO',
                        detailValue: usageTrackingData.catalog.searchQuery,
                        custom: 'Y',
                    });
                } else if (usageTrackingData.catalog.lookupType === 'DRILL_DOWN' || usageTrackingData.catalog.lookupType === 'JOB') {
                    for (i = 0; i < data.length; i++) {
                        results.push({
                            detailType: 'SELECTED_PART_TYPE',
                            detailValue: data[i].partTypeId,
                            custom: 'Y',
                        });

                        results.push({
                            detailType: 'PART_NAME',
                            detailValue: data[i].partTypeName || '',
                            custom: 'Y',
                        });

                        // check to see if user searched a term that resulted in having filteredBrands
                        if (filteredBrands.length > 0 && usageTrackingData.catalog.searchQuery !== '') {
                            results.push({
                                detailType: 'MANUFACTURER',
                                detailValue: usageTrackingData.catalog.searchQuery,
                                custom: 'Y',
                            });
                        }
                    }
                }
            }

            return results;
        };
    },
    assembleLookupDetails(_, getters) {
        return (detailsData, uuid) => {
            const lookUpDetailsData = [];

            for (let i = 0; i < detailsData.length; i++) {
                lookUpDetailsData.push({
                    key: {
                        uuid: uuid || getters.getCurrentUUID,
                        detailType: detailsData[i].detailType,
                        servicingStoreNumber: getters.getStoreId,
                    },
                    detailValue: detailsData[i].detailValue,
                    custom: 'Y',
                });
            }
            return lookUpDetailsData;
        };
    },
    assembleLookupAttributes(_, getters) {
        return (attributeData, uuid) => {
            const lookUpAttributeData = [];

            for (let i = 0; i < attributeData.length; i++) {
                lookUpAttributeData.push({
                    key: {
                        attributeTypeId: attributeData[i].attributeTypeID,
                        attributeId: attributeData[i].attributeID,
                        attributeValueId: attributeData[i].attributeValueID,
                        uuid: uuid || getters.getCurrentUUID,
                        servicingStoreNumber: getters.getStoreId,
                    },
                    attributeState: 'ANSWERED',
                    partTypeId: null,
                });
            }

            return lookUpAttributeData;
        };
    },
    assembleResultsSummary(_, getters) {
        return (data, uuid) => {
            const results = [];

            for (let i = 0; i < data.length; i++) {
                results.push({
                    key: {
                        uuid: uuid || getters.getCurrentUUID,
                        servicingStoreNumber: getters.getStoreId,
                        date: getters.getCurrentDate,
                        partTypeId: data[i].partTypeId,
                    },
                    productsReturned: data[i].countProducts,
                    stockingProductsReturned: data[i].countStocking,
                    nonStockProductsReturned: data[i].countNonStocking,
                    specialOrderProductsReturned: data[i].countSpecialOrder,
                    outOfStockProductsReturned: data[i].countOutOfStock,
                    countDisplayed: data[i].countDisplayed,
                    duration: 0,
                });
            }

            return results;
        };
    },
    assembleResultsDetails(_, getters) {
        return (data, isActionItem, uuid, vehicleId, isFromQuote) => {
            const results = [];
            let uuidProducts;
            let uuidProductKey;
            let finalUUID;
            let partId;
            let isStockingItem;

            if (uuid) {
                finalUUID = uuid;
            } else if (isActionItem && data && data.length > 0) {
                uuidProducts = getters.getVehicleProducts(vehicleId, isFromQuote);
                uuidProductKey = `${data[0].brandCode}|${data[0].catalogItemId}`;
                if (uuidProducts && uuidProducts[uuidProductKey]) {
                    finalUUID = uuidProducts[uuidProductKey].uuid.uuid;
                }
            }

            if (!finalUUID) {
                finalUUID = getters.getCurrentUUID;
            }

            for (let i = 0; i < data.length; i++) {
                partId = `${data[i].brandCode}|${data[i].catalogItemId}`;
                isStockingItem = data[i].stocking;
                if (isStockingItem === null || typeof isStockingItem === 'undefined') {
                    isStockingItem = getters.getIsStockingItem(partId, vehicleId, isFromQuote);
                }
                results.push({
                    key: {
                        uuid: finalUUID,
                        servicingStoreNumber: getters.getStoreId,
                        partTypeId: data[i].partTypeId,
                        line: data[i].line,
                        item: data[i].item,
                        productState: data[i].productState,
                    },
                    date: getters.getCurrentDate,
                    fitmentNotes: '', // BRD says to always leave blank/empty string
                    quotedPrice: data[i].quotedPrice,
                    desiredQuantity: data[i].desiredQuantity,
                    localQOH: data[i].localQOH || getters.getLocalQuantityOnHand(partId, vehicleId, isFromQuote),
                    hubQOH: data[i].hubQOH,
                    dcQOH: data[i].dcQOH,
                    stockingItem: isStockingItem,
                    onOrder: data[i].onOrder,
                    onBackOrder: data[i].onBackOrder,
                    brandCode: data[i].brandCode,
                });
            }

            return results;
        };
    },
    createCatalogLookup(_, getters) {
        return ({ partTypes, attributes = [], isRelatedProduct = false, filteredBrands = [] } = {}) => {
            const catalogLookupObj = {
                lookupSummary: getters.getLookupSummaryData(isRelatedProduct ? 'REL_ITEM' : undefined),
                lookupDetails: getters.getLookupDetailsData(isRelatedProduct, filteredBrands),
                lookupAttributes: attributes.map((attribute) =>
                    getAttributeData({
                        attributeType: attribute.attributeType,
                        attributeID: attribute.attributeId,
                        attributeValueID: attribute.valueId,
                    })
                ),
            };

            if (partTypes) {
                catalogLookupObj.resultsSummary = getResultSummaryData(partTypes);
                catalogLookupObj.resultsDetails = getResultDetailsData(partTypes, 'LOOKUP');
            }

            return catalogLookupObj;
        };
    },
    getVehicleProducts(_, getters) {
        return (vehicleId, isFromQuote) => {
            const { getCurrentVehicleProducts, getVehicles } = getters;

            if (!vehicleId) {
                return getCurrentVehicleProducts(isFromQuote);
            }

            const vehicle = getVehicles(isFromQuote)[vehicleId];

            return vehicle?.products || {};
        };
    },
    assembleLookupSummary(_, getters) {
        return (summaryData, uuid) => {
            const lookUpSummaryData = [];
            let vehicleId;
            let vehicleLookupType;

            for (let i = 0; i < summaryData.length; i++) {
                if (summaryData[i].vehicleId !== null) {
                    if (typeof summaryData[i].vehicleId === 'undefined') {
                        vehicleId = getters.getVehicleBaseId;
                    } else {
                        ({ vehicleId } = summaryData[i]);
                    }
                }

                if (summaryData[i].vehicleLookupType !== null) {
                    if (typeof summaryData[i].vehicleLookupType === 'undefined') {
                        vehicleLookupType = getters.getVehicleLookupType;
                    } else {
                        ({ vehicleLookupType } = summaryData[i]);
                    }
                } else {
                    vehicleLookupType = 'NULL';
                }

                lookUpSummaryData.push({
                    key: {
                        date: getters.getCurrentDate,
                        application: getters.getApplicationId,
                        partTypeId: summaryData[i].partTypeId,
                        uuid: uuid || getters.getCurrentUUID,
                        servicingStoreNumber: getters.getStoreId,
                    },
                    parentPartTypeId: getters.lookupParentPartType(summaryData[i].partTypeId),
                    catalogLookupType: summaryData[i].catalogLookupType || getters.getCatalogLookupType,
                    vehicleLookupType,
                    vehicleId,
                    customerId: getters.getCustomerId,
                    customerZipCode: getters.getCustomerZipCode,
                    catalogVersion: getters.getCatalogVersion,
                });
            }

            return lookUpSummaryData;
        };
    },
};

export const mutations = {
    disableUsageTracking(state) {
        state.isDisabled = true;
        state.usageTrackingData = null;
    },
    setUsageTrackingData(state, usageTrackingJsonResponse) {
        state.usageTrackingData = usageTrackingJsonResponse;
        if (!state.isDisabled) {
            state.tokenParam = `?token=${state?.staticData?.application?.authorization}`;
        }
    },
    resetUsageTrackingData(state) {
        state.isDisabled = false;
        state.usageTrackingData = null;
    },
    setCurrentUUID(state, id) {
        state.usageTrackingData.uuid.uuid = id;
    },
    overwriteUsageTrackingData(state, utData) {
        const { paths } = state.usageTrackingData;
        state.usageTrackingData = utData;
        state.usageTrackingData.paths = paths;
    },
    setLoadUsageTrackingInProgress(state) {
        state.isLoadUsageTrackingInProgress = true;
    },
    updateQuoteProductsVehicles(state, vehicles) {
        // update global usage tracking details so future logging has up to date data
        state.usageTrackingData.catalog.quoteProducts.vehicles = vehicles;
    },
    setStaticData(state, staticData) {
        state.staticData = {
            application: {
                authorization: staticData.usageTrackingAuth,
                id: staticData.usageTrackingApplicationId,
            },
            paths: {
                lookupSummaryPath: staticData.usageTrackingLookupSummaryPath,
                lookupDetailsPath: staticData.usageTrackingLookupDetailsPath,
                lookupAttributesPath: staticData.usageTrackingLookupAttributesPath,
                resultsSummaryPath: staticData.usageTrackingResultsSummaryPath,
                resultsDetailsPath: staticData.usageTrackingResultsDetailsPath,
            },
        };
    },
};

const createUsageTrackingAction = (fn) => async (ctx, payload) => {
    const { state, dispatch } = ctx;

    await dispatch('loadUsageTracking');
    if (state.isDisabled) return;
    return fn(ctx, payload);
};

let loadUsageTrackingCachedRequest;

export const actions = {
    async loadUsageTracking(ctx, isForceReload) {
        const { commit, state, dispatch, rootGetters } = ctx;
        const { isDisabled, usageTrackingData } = state;

        // no need to do anything if disabled or we already have data
        if (isDisabled || usageTrackingData) return;

        try {
            await dispatch('requestIfIdle', ['getUser', 'getFeatures', 'getCompany'], { root: true });
            const { isFeatureDisabled, isDemo, isTeamMember } = rootGetters;

            // check feature flag, or disable for team members and demo shops
            if (isFeatureDisabled('usage_tracking' || isTeamMember || isDemo)) {
                commit('disableUsageTracking');
                return;
            }

            if (isForceReload || !loadUsageTrackingCachedRequest) {
                loadUsageTrackingCachedRequest = axios.get(fcoUrl('/usageTracking/getUsageTrackingJson'));
            }

            const { data: usageTrackingJsonResponse } = await loadUsageTrackingCachedRequest;

            commit('setUsageTrackingData', usageTrackingJsonResponse);
        } catch {
            commit('disableUsageTracking');
        }
    },
    resetUsageTrackingData({ commit, dispatch }) {
        commit('resetUsageTrackingData');
        dispatch('loadUsageTracking', true);
    },
    async requestNewUUID({ state }, ignoreSession) {
        const { data } = await axios.get(fcoUrl(state.usageTrackingData.paths.newUuid));

        if (!ignoreSession) {
            sessionStorage.setItem('ut_uuid', data.uuid);
        }

        return data;
    },
    async refreshCurrentUUID({ state, commit }) {
        try {
            const { data } = await axios.get(fcoUrl(state.usageTrackingData.paths.existingUuid));
            commit('setCurrentUUID', data?.uuid);
        } catch {
            // fail silently
        }
    },
    logLookupSummary({ state }, lookUpSummaryData) {
        if (state.isDisabled) return;

        navigator.sendBeacon(state.staticData.paths.lookupSummaryPath + state.tokenParam, JSON.stringify(lookUpSummaryData));
    },
    logLookupDetails({ state }, lookupDetailsData) {
        if (state.isDisabled) return;

        navigator.sendBeacon(state.staticData.paths.lookupDetailsPath + state.tokenParam, JSON.stringify(lookupDetailsData));
    },
    logLookupAttributes({ state }, lookUpAttributesData) {
        if (state.isDisabled) return;

        navigator.sendBeacon(state.staticData.paths.lookupAttributesPath + state.tokenParam, JSON.stringify(lookUpAttributesData));
    },
    logResultsSummary({ state }, resultsSummary) {
        if (state.isDisabled) return;

        navigator.sendBeacon(state.staticData.paths.resultsSummaryPath + state.tokenParam, JSON.stringify(resultsSummary));
    },
    logResultsDetails({ state }, resultsDetails) {
        if (state.isDisabled) return;

        navigator.sendBeacon(state.staticData.paths.resultsDetailsPath + state.tokenParam, JSON.stringify(resultsDetails));
    },
    async addPartsToQuoteProducts({ state, commit }, info) {
        try {
            if (state.isDisabled) return;

            const { data } = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                data: JSON.stringify(info),
                url: fcoUrl(state.usageTrackingData.paths.addPartsToQuote),
            });

            commit('updateQuoteProductsVehicles', data.vehicles);
        } catch {
            // TODO: will need to add to failure queue in future phase
        }
    },
    async removePartsFromQuoteProducts({ state }, data) {
        if (state.isDisabled) return;

        await axios({
            method: 'post',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            data: JSON.stringify(data),
            url: fcoUrl(state.usageTrackingData.paths.removePartsFromQuote),
        }).catch(() => {
            // TODO: will need to add to failure queue in future phase
        });
    },
    logCatalogLookup({ state, getters, dispatch }, { data, optionalUUID }) {
        if (!state.isDisabled) {
            const finalUUID = optionalUUID || getters.getCurrentUUID;

            if (!optionalUUID) {
                const lastTrackedLookupUUID = sessionStorage.getItem('ut_uuid');
                const isAlreadyTracked = !!lastTrackedLookupUUID && lastTrackedLookupUUID === finalUUID;

                sessionStorage.setItem('ut_uuid', finalUUID);

                // If we're using the default UUID and we've already tracked that lookup, just bail.
                if (isAlreadyTracked) {
                    return;
                }
            }

            if (data?.lookupDetails?.length > 0) {
                dispatch('logLookupDetails', getters.assembleLookupDetails(data.lookupDetails, optionalUUID));
            }

            if (data?.lookupSummary?.length > 0) {
                dispatch('logLookupSummary', getters.assembleLookupSummary(data.lookupSummary, optionalUUID));
            }

            if (data?.resultsSummary?.length > 0) {
                dispatch('logResultsSummary', getters.assembleResultsSummary(data.resultsSummary, optionalUUID));
            }

            if (data?.resultsDetails?.length > 0) {
                dispatch('logResultsDetails', getters.assembleResultsDetails(data.resultsDetails, false, optionalUUID));
            }

            if (data?.lookupAttributes?.length > 0) {
                dispatch('logLookupAttributes', getters.assembleLookupAttributes(data.lookupAttributes, optionalUUID));
            }

            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                url: fcoUrl(state.usageTrackingData.paths.completeCatalogLookupEvent),
                data: JSON.stringify({ uuid: finalUUID }),
            }).catch(() => {
                // nothing for now
            });
        }
        /* User performs a catalog lookup (Most Popular or All Part Types tabs) and views PLP for part type(s) selected.
            - LookupSummary (one per part type)X
            - LookupAttribute (if applicable)X
            - LookupDetails (log part types and vehicle selected, if applicable) X
            - ResultsSummary (one per part type returned)
            - ResultsDetails (one per IMASTER part returned)
            * */
    },
    // this is the function we make available to our app for logging catalog lookups
    // other internal UT service functions can either call this function or logCatalogLookup() directly (depending on the scenario)
    catalogLookup: createUsageTrackingAction(async (ctx, { catalogLookupData, optionalUUID }) => {
        const { dispatch, getters } = ctx;

        await dispatch('refreshCurrentUUID');

        return dispatch('logCatalogLookup', {
            data: getters.createCatalogLookup(catalogLookupData),
            optionalUUID,
        });
    }),
    async logAddPartResultsDetails(ctx, { addProductResultsDetails, optionalUUID }) {
        const { state, getters, dispatch } = ctx;
        if (state.isDisabled) return;

        const usageTrackingProduct =
            getters.getCurrentVehicleProducts()[`${addProductResultsDetails.brandCode}|${addProductResultsDetails.catalogItemId}`];

        if (!usageTrackingProduct) return;

        const quoteProduct = {
            ...usageTrackingProduct,
            vehicleId: getters.getVehicleId,
            baseVehicleId: getters.getVehicleBaseId,
        };

        await dispatch('addPartsToQuoteProducts', [quoteProduct]);
        dispatch('logResultsDetails', getters.assembleResultsDetails([addProductResultsDetails], true, optionalUUID));

        /* User adds part to the Quote (from the PLP or PDP).
            - ResultsDetails ("addToInvc" type)
            * */
    },
    relatedPartTypesLookup: createUsageTrackingAction(async (ctx, { partTypes, vehicle }) => {
        try {
            const { dispatch, getters, commit } = ctx;
            const uuid = await dispatch('requestNewUUID');
            const newProducts = partTypes.flatMap(({ partTypeId, parentPartTypeId, completeProducts }) =>
                completeProducts.map((product) => {
                    const { stocking, localQOH } = getLocalQtyDetails(product);

                    return {
                        vehicleId: vehicle.id,
                        baseVehicleId: vehicle.vehicleId,
                        partId: product.catalogKey.formattedProductKey,
                        /**
                         * Need to hard code this to DRILL_DOWN because choosing a new related part type from
                         * related parts drawer is technically a drill down. We need this value to update correctly
                         * in case a user started with a PART_NO (search) lookup so global usage tracking property gets updated
                         * and future logging of related part types still logs things correctly without getting hung up on PART_NO checks
                         */
                        lookupType: 'DRILL_DOWN',
                        partTypeId,
                        parentPartTypeId,
                        uuid,
                        stocking,
                        localQuantityOnHand: localQOH,
                    };
                })
            );
            const { data: usageTrackingDataResponse } = await axios.post(getters.getAddPartsToCatalogURL, newProducts);

            commit('overwriteUsageTrackingData', usageTrackingDataResponse);
            await dispatch('catalogLookup', {
                catalogLookupData: getters.createCatalogLookup({ partTypes }),
                optionalUUID: uuid.uuid,
            });
        } catch {
            // do nothing for now
        }
    }),
    relatedProductsLookup: createUsageTrackingAction(async (ctx, lookupResults) => {
        try {
            const { getters, dispatch, commit } = ctx;
            const vehicleId = getters.getVehicleId;
            const baseVehicleId = getters.getVehicleBaseId;
            const data = {
                ...lookupResults,

                // set the unknown defaults for these, as these related products won't have this information
                partTypeId: 0,
                parentPartTypeId: null,
            };

            const uuid = await dispatch('requestNewUUID');
            const newProducts = data.completeProducts.map((product) => {
                const { stocking, localQOH } = getLocalQtyDetails(product);

                return {
                    vehicleId,
                    baseVehicleId,
                    partId: product.catalogKey.formattedProductKey,
                    lookupType: getters.getCatalogLookupType,
                    parentPartTypeId: null,
                    uuid,
                    stocking,
                    localQuantityOnHand: localQOH,
                };
            });

            const { data: usageTrackingOverwrite } = await axios.post(getters.getAddPartsToCatalogURL, newProducts);

            commit('overwriteUsageTrackingData', usageTrackingOverwrite);

            sessionStorage.setItem(
                'curRelatedPartLookup',
                JSON.stringify({
                    lookupData: getters.createCatalogLookup({ partTypes: [data], isRelatedProduct: true }),
                    uuid: uuid.uuid,
                    hasBeenLogged: false,
                })
            );
        } catch {
            // do nothing for now
        }
    }),
    startCatalogLookup: createUsageTrackingAction(async (_, { resetUUID = true, clearSelectedPartTypes = true, lookupType = 'RELEVANT' } = {}) => {
        try {
            return axios.post(fcoUrl('/usageTracking/startCatalogLookup'), { resetUUID, clearSelectedPartTypes, lookupType });
        } catch {
            // Fail silently
        }
    }),
    addPartToQuote: createUsageTrackingAction(async ({ dispatch }, { addedProductData, order: { onOrder, onBackOrder } }) => {
        const { brandCode, itemCost, itemNumber, itemOrigin, itemOriginDetails, itemQuantity, line, locations, partNumber } = addedProductData;
        const isRelatedPart = itemOrigin === 'CROSS_SELL_PR_PRODUCT';
        const curRelatedProductLookupData = isRelatedPart ? JSON.parse(sessionStorage.getItem('curRelatedPartLookup')) : {};
        let hubQOH = -1;
        let dcQOH = -1;
        let uuid;

        locations.forEach(({ quantityOnHand, locationType }) => {
            if (locationType === 'HUB') {
                hubQOH = quantityOnHand;
            } else if (locationType === 'DISTRIBUTION_CENTER') {
                dcQOH = quantityOnHand;
            }
        });

        const resultDetails = {
            partTypeId: validatePartTypeId(itemOriginDetails),
            line,
            item: itemNumber,
            catalogItemId: partNumber,
            fitmentNotes: '', // BRD says always leave this blank
            productState: 'ADD_TO_INVOICE',
            quotedPrice: itemCost,
            desiredQuantity: itemQuantity,
            hubQOH,
            dcQOH,
            onOrder,
            onBackOrder,
            brandCode,
        };

        if (isRelatedPart && !curRelatedProductLookupData.hasBeenLogged) {
            ({ uuid } = curRelatedProductLookupData);
            await dispatch('catalogLookup', {
                catalogLookupData: curRelatedProductLookupData.lookupData,
                optionalUUID: uuid,
            });
            curRelatedProductLookupData.hasBeenLogged = true;
            sessionStorage.setItem('curRelatedPartLookup', JSON.stringify(curRelatedProductLookupData));
        }

        try {
            await dispatch('logAddPartResultsDetails', {
                addProductResultsDetails: resultDetails,
                optionalUUID: uuid,
            });
        } catch {
            // Some error handling
        }
    }),
    addKitPartToQuote: createUsageTrackingAction(async ({ dispatch }, { addedProductData, kitData }) => {
        // this is specific to when we add kit wizard products to quote, it uses a mix of response data and data sent to add to quote function.
        let hubQOH = -1;
        let dcQOH = -1;
        let curLoc;
        let curQOH;

        for (let i = 0; i < addedProductData.locations.length; i++) {
            curLoc = addedProductData.locations[i];
            curQOH = curLoc.quantityOnHand >= 0 ? curLoc.quantityOnHand : 0;
            if (curLoc.locationType === 'HUB') {
                hubQOH = curQOH;
            } else if (curLoc.locationType === 'DISTRIBUTION_CENTER') {
                dcQOH = curQOH;
            }
        }

        const resultDetails = {
            partTypeId: kitData.partTypeId || addedProductData.itemOriginDetails,
            line: addedProductData.line,
            item: kitData.legacyKey.itemId,
            catalogItemId: kitData.catalogKey.itemId,
            fitmentNotes: '', // BRD says always leave this blank
            productState: 'ADD_TO_INVOICE',
            quotedPrice: kitData.itemCost,
            desiredQuantity: kitData.userSpecifiedQuantity,
            hubQOH,
            dcQOH,
            onOrder: kitData.partPriceAvailabilityResponse.onOrder,
            onBackOrder: kitData.partPriceAvailabilityResponse.onBackOrder,
            brandCode: addedProductData.brandCode,
        };

        try {
            await dispatch('logAddPartResultsDetails', { addProductResultsDetails: resultDetails });
        } catch {
            // Fail silently
        }
    }),
    addPrebuiltKitToQuote: createUsageTrackingAction((ctx, { addedProducts, kit, partTypeId }) => {
        try {
            const { dispatch, getters, state } = ctx;
            const { selectedPartTypes } = state.usageTrackingData.catalog;
            let parentPartTypeId;

            // first log new lookup summary for this part type id
            if (selectedPartTypes.length > 0) {
                // if selected part types is populated when looking up kits it is safe to select
                // one and only slot as users can only select one catalog lookup type when choosing kits
                parentPartTypeId = selectedPartTypes[0].partTypeId;
            } else {
                parentPartTypeId = 0;
            }

            dispatch(
                'logLookupSummary',
                getters.assembleLookupSummary([
                    {
                        partTypeId,
                        parentPartTypeId,
                    },
                ])
            );

            addedProducts.forEach((addedProduct) => {
                // create specific object for usage tracking to consume
                const kitData = {
                    partType: {
                        kits: [kit],
                    },
                };

                // first determine which kitWizardProducts product matches our current response product
                const curPrebuiltKitProduct = determineKitProductByAddQuoteResponse(addedProduct, kitData);

                // should always have a product match but put this here just in case to prevent any js errors
                if (curPrebuiltKitProduct) {
                    dispatch('addKitPartToQuote', {
                        addedProductData: addedProduct,
                        kitData: curPrebuiltKitProduct,
                    });
                }
            });
        } catch (ex) {
            /*
            Ignore usage tracking errors for now, invalid usage tracking records are automatically
            scrubbed at the end of the day
            */
        }
    }),
    plpMoreInformation: createUsageTrackingAction((ctx, { qty, productState, partData, partTypeId, optionalUUID }) => {
        const { dispatch, getters } = ctx;
        const data = getResultDetailsObj(qty, productState, partData, partTypeId);
        dispatch('logResultsDetails', getters.assembleResultsDetails(data, true, optionalUUID));
        /* On the PLP, user clicks on a part image, part name, or the "More Information" link.
            - ResultsDetails ("vwDtl" type)
        * */
    }),
    removePartFromQuote: createUsageTrackingAction(async (ctx, { data, groupId, itemId }) => {
        const { dispatch, getters } = ctx;
        /* User removes part from Quote (from the Quote page or Mini-Quote).
        - ResultsDetails ("rmvFromInvc" type)
        * */
        const resp = await axios.get(fcoUrl(`/product/extraInfo?mfg=${groupId}&partNumber=${itemId}`));

        data.product.catalogKey = { itemId, groupId };
        data.product.legacyKey = resp.data;

        const resultsDetails = buildResultsDetailsObj(data.product, 'REM_FROM_INVOICE');

        dispatch('logResultsDetails', getters.assembleResultsDetails([resultsDetails], true, null, data.vehicleId, true));
        await dispatch('removePartsFromQuoteProducts', [
            {
                vehicleId: data.vehicleId,
                partId: `${resultsDetails.brandCode}|${resultsDetails.catalogItemId}`,
            },
        ]).catch(() => {
            // fail silently
        });
    }),
    viewPartFromQuote: createUsageTrackingAction(async (ctx, { data, line, itemNumber }) => {
        const { dispatch, getters } = ctx;
        const getCatalogKeyURL = fcoUrl(`/product/extraInfo?&sourceType=LEGACY&targetType=CATALOG&mfg=${line}&partNumber=${itemNumber}`);
        const { data: catalogKey } = await axios.get(getCatalogKeyURL);

        data.product.catalogKey = catalogKey;

        const resultsDetails = getters.assembleResultsDetails([buildResultsDetailsObj(data.product, 'VW_DETAILS')], true, null, data.vehicleId, true);
        dispatch('logResultsDetails', resultsDetails);
    }),
    quickAddPartsToQuote: createUsageTrackingAction(async (ctx, { products, isWorksheet, vehicleId }) => {
        const { state, dispatch, getters } = ctx;
        /* User selects parts on the Quick Order box, then clicks Go To Quote.
        - LookupSummary (one record)
        - LookupDetails (log part numbers entered)
        - ResultsSummary (one record with part type = 0)
        - ResultsDetails ("lookup" records for all IMASTER parts. "addToInvc" records for all parts.)
        * */
        const uuid = await axios.get(fcoUrl(state.usageTrackingData.paths.disposableUuid));
        const lookupType = isWorksheet ? 'QUICK_ADD' : 'PART_NO';
        const utObj = {
            lookupSummary: [
                {
                    partTypeId: 0,
                    parentPartTypeId: 0,
                    catalogLookupType: lookupType,
                    vehicleLookupType: null,
                    vehicleId: null,
                },
            ],
            lookupDetails: [
                {
                    detailType: 'TYPE',
                    detailValue: 'all',
                },
            ],
            resultsSummary: [],
            resultsDetails: [],
            quoteProducts: [],
        };
        const stockingTotals = {
            countStocking: 0,
            countNonStocking: 0,
            countSpecialOrder: 0,
            countOutOfStock: 0,
        };
        let ppAvailability;
        let resultsDetailsAddToInvoice;

        for (let partIndex = 0; partIndex < products.length; partIndex++) {
            ppAvailability = products[partIndex].partPriceAvailabilityResponse;
            if (ppAvailability.stocking) {
                stockingTotals.countStocking += 1;
            }
            if (isProductOutOfStock(products[partIndex])) {
                stockingTotals.countOutOfStock += 1;
            }
            if (ppAvailability.nonStocking && ppAvailability.itemMaster) {
                stockingTotals.countNonStocking += 1;
            }
            if (!ppAvailability.itemMaster) {
                stockingTotals.countSpecialOrder += 1;
            }

            utObj.lookupDetails.push({
                detailType: 'PART_NO',
                detailValue: products[partIndex].catalogKey.itemId,
            });

            resultsDetailsAddToInvoice = buildResultsDetailsObj(products[partIndex], 'ADD_TO_INVOICE', true);
            utObj.resultsDetails.push(resultsDetailsAddToInvoice);
            if (ppAvailability.itemMaster) {
                utObj.resultsDetails.push(buildResultsDetailsObj(products[partIndex], 'LOOKUP', true));
            }

            utObj.quoteProducts.push({
                vehicleId,
                baseVehicleId: null,
                partId: `${resultsDetailsAddToInvoice.brandCode}|${resultsDetailsAddToInvoice.catalogItemId}`,
                lookupType,
                parentPartTypeId: 0,
                uuid,
                stocking: resultsDetailsAddToInvoice.stocking,
                localQuantityOnHand: resultsDetailsAddToInvoice.localQOH,
            });
        }

        utObj.resultsSummary.push({
            partTypeId: 0,
            countProducts: products.length,
            countStocking: stockingTotals.countStocking,
            countNonStocking: stockingTotals.countNonStocking,
            countSpecialOrder: stockingTotals.countSpecialOrder,
            countOutOfStock: stockingTotals.countOutOfStock,

            // This countDisplayed value needs to be hardcoded for RWD
            countDisplayed: -1,
            duration: 0,
        });
        dispatch('logLookupDetails', getters.assembleLookupDetails(utObj.lookupDetails, uuid.uuid));
        dispatch('logLookupSummary', getters.assembleLookupSummary(utObj.lookupSummary, uuid.uuid));
        dispatch('logResultsSummary', getters.assembleResultsSummary(utObj.resultsSummary, uuid.uuid));
        dispatch('logResultsDetails', getters.assembleResultsDetails(utObj.resultsDetails, false, uuid.uuid));
        dispatch('addPartsToQuoteProducts', utObj.quoteProducts).catch(() => {
            // Fail silently
        });
    }),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
