export const ACCOUNT_ID = 200106309927;
export const SCRIPT_SRC_URL = '//ee.channels.ocs.oraclecloud.com/js/eesvcs.js';
export const CHAT_READY = 'chatReady';
export const CHAT_DISABLED = 'chatDisabled';

export const openChat = () => {
    /*
    Oracle EE is monitoring this variable for changes,
    and when it changes the 'open chat' behavior is triggered.
    */
    window.chatButtonClickedFlag *= -1;
};
const onOracleScriptLoaded = () => {
    window.EESvcs.start();
};
const addOracleEvents = () => {
    /* exposes functions for Oracle script to call */
    window.dispatchChatReadyEvent = () => {
        window.dispatchEvent(new Event(CHAT_READY));
    };
    window.dispatchChatDisabledEvent = () => {
        window.dispatchEvent(new Event(CHAT_DISABLED));
    };
};

export const createScript = () => {
    const oracleScript = document.createElement('script');

    oracleScript.src = SCRIPT_SRC_URL;
    oracleScript.type = 'application/javascript';
    oracleScript.async = true;
    oracleScript.defer = true; // fallback support for browsers that does not support async
    oracleScript.onload = onOracleScriptLoaded;

    document.head.appendChild(oracleScript);
};

export const init = (callback = () => {}) => {
    window.EESvcs = { eeid: ACCOUNT_ID };
    window.chatButtonClickedFlag = 1;
    createScript();
    addOracleEvents();

    callback();
};
