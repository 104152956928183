import store, { state } from '../../store/store';
import { CHAT_READY, CHAT_DISABLED, init } from './oracleChat';

let bodyObserver = null;

const hideChatButton = () => {
    const $button = document.querySelector('.wcfs-wrapper .wcfs-button');

    $button?.classList.add('d-none');
};
const onHideButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    hideChatButton();
    store.commit('setIsChatButtonHidden', true);
};
const bodyMutated = () => {
    const $hideButton = document.querySelector('#chatHideButtonButton');

    if ($hideButton) {
        if (state.isChatButtonHidden) {
            hideChatButton();
        } else {
            $hideButton.addEventListener('click', onHideButtonClick);
        }

        bodyObserver.disconnect();
    }
};
const initEvents = () => {
    window.addEventListener(CHAT_READY, () => {
        const $body = document.querySelector('body');

        bodyObserver = new MutationObserver(bodyMutated);
        bodyObserver.observe($body, { childList: true, subtree: true });
        store.commit('setIsChatReady', true);
    });
    window.addEventListener(CHAT_DISABLED, () => {
        store.commit('setIsChatDisabled', true);
    });
};
export const initOracleChat = () => {
    init(() => initEvents());
};

export default {
    initOracleChat,
};
