var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "smart-product-search-container",
    },
    [
      _c("FcoTypeahead", {
        ref: "siteSearch",
        attrs: {
          inputClass: "fco-typeahead-input",
          value: _vm.inputValue,
          "menu-class": "main-search-results",
          results: _vm.curResults,
          resultsUseCategories: _vm.isEntSearchEnabled,
          placeholder: _vm.placeholder,
          "map-result": function (_ref) {
            var displayName = _ref.displayName
            return displayName
          },
          minChars: 1,
          mode: "custom",
          maxResults: 99,
          showSearchDuringInput: true,
          filterInput: _vm.cleanTerm,
          menuOffset: [0, 0],
          disableAutoResultSelect: _vm.isEntSearchEnabled,
          "ignore-left-right-arrow-keypress": "",
        },
        on: {
          focus: function ($event) {
            return _vm.$emit("focus")
          },
          search: _vm.handleSearch,
          "input-no-debounce": _vm.handleActiveInput,
          "enter-press-before-search": function ($event) {
            _vm.quickEntered = true
          },
          select: _vm.handleSelect,
        },
      }),
      _vm._v(" "),
      _vm.showNewQuotePrompt
        ? _c("QuoteSelectionModal", {
            on: {
              close: _vm.handleQuotePromptClose,
              "start-new-quote": function ($event) {
                return _vm.startNewQuote(_vm.tempVehicle)
              },
              "use-existing-quote": function ($event) {
                return _vm.addToExistingQuote(_vm.tempVehicle)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }