<template>
    <nav :class="['admin-bar', { 'admin-bar-vertical': isVertical, 'admin-bar-horizontal': isHorizontal }]">
        <div :class="['admin-bar-container d-flex py-1', { 'align-items-center': isHorizontal, 'flex-column': isVertical }]">
            <span class="admin-bar-account-name pr-3 py-1 my-1 mr-2">
                <VueSkeletonLoader v-if="!accountName" type="rect" :width="125" :height="18" :rounded="true" />
                <template v-else>{{ accountName }}</template>
            </span>
            <FcoSearchSelect
                v-if="shops.length > 1"
                :value="shopSearchVal"
                :allow-empty="false"
                :options="shops"
                :map-option="({ id, shopName }) => ({ value: id, label: shopName })"
                :menu-container="$refs.shopSearch"
                :input-class="['shop-select-input']"
                mode="fuzzy"
                :searchKeys="['label']"
                :placeholder="tempShopName"
                class="shop-select bg-light text-black"
                ref="shopSearch"
                @focus="handleFocus"
                @blur="handleBlur"
                @select="handleShopSelect"
            />
            <span v-else class="admin-bar-shop-name">
                <VueSkeletonLoader v-if="!currentShop?.id" type="rect" :width="100" :height="18" :rounded="true" />
                <template v-else>{{ currentShop?.shopName }}</template>
            </span>
            <ul :class="['nav admin-bar-nav', { 'ml-auto': isHorizontal, 'flex-column': isVertical }]">
                <li class="nav-item position-relative">
                    <ChatButton class="nav-link" :label="fcoM('common.chatForHelp', 'Chat for Help')" />
                    <div class="chat-tooltip px-3 pb-3 shadow" v-if="showChatTooltip">
                        <div class="text-right">
                            <span class="font-weight-bold close-x" @click="showChatTooltip = false">X</span>
                        </div>
                        <p class="font-weight-bold pb-1 mb-0">{{ fcoM('rs.authentication.needAssistanceHeading', 'Need Assistance?') }}</p>
                        <p class="mb-0">{{ fcoM('rs.homeMain.chatTooltipMessage', 'Chat Now with a O\'Reilly Pro Support Technician.') }}</p>
                    </div>
                </li>
                <li class="nav-item" v-if="isMasquerade">
                    <a id="masquerade" class="nav-link nav-link-masquerade" href="javascript:void(0)" @click.prevent.stop="handleUnmasqueradeClick">
                        <Icon name="eye" class="clickable text-md align-text-bottom" />
                        {{ fcoM('common.masquerade', 'Masquerade') }}
                    </a>
                </li>
                <li class="nav-item">
                    <LanguageSelectButton button-class="header-language-select-button" label-class="sr-only" />
                </li>
                <li class="nav-item">
                    <FcoLink class="nav-link qaa-username-link" to="/admin/user" :href="fcoUrl('/user/editpreferences.html?reset=true')">
                        <Icon name="user" class="clickable text-md align-text-bottom" />
                        <VueSkeletonLoader v-if="!user?.userId" type="rect" :width="80" :height="18" :rounded="true" />
                        <template v-else>{{ loggedInUserText }}</template>
                    </FcoLink>
                </li>
                <li class="nav-item" v-if="userCanAccessSupport">
                    <FcoLink class="nav-link" to="/support" :href="fcoUrl('/admin/support.html')">
                        <Icon name="wrench" class="clickable text-md align-text-bottom" />
                        {{ fcoM('common.support', 'Support') }}
                    </FcoLink>
                </li>
                <li class="nav-item">
                    <a
                        id="adminLogOut"
                        class="nav-link pr-0"
                        :href="isSPA ? '#' : fcoUrl(`/logout.html?${csrfObject.parameterName}=${csrfObject.token}`)"
                        @click="handleLogoutClick"
                    >
                        <Icon name="sign-out" class="clickable text-md align-text-bottom" />
                        {{ fcoM('rs.homeMain.logout', 'Logout') }}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import axios from 'axios';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { FcoSearchSelect } from 'fco/src/vue/components/searchselect';
import { mapGetters, mapState } from 'vuex';
import { deleteTableState } from '@/helper/persistTableState';
import Icon from '@/common/components/Icon.vue';
import { stringFormat } from '../../fcoModules/utilities';
import { Role } from '../constants/user';
import analytics from '../../fcoModules/analytics';
import ChatButton from './ChatButton.vue';
import FcoLink from './FcoLink.vue';
import LanguageSelectButton from './LanguageSelectButton.vue';
import featureFlagsMixin from '../mixins/featureFlagsMixin';

export default {
    name: 'AdminBar',
    mixins: [featureFlagsMixin],
    components: { ChatButton, FcoSearchSelect, Icon, VueSkeletonLoader, FcoLink, LanguageSelectButton },
    props: {
        direction: {
            type: String,
            default: 'horizontal',
            validator(direction) {
                return ['vertical', 'horizontal'].includes(direction);
            },
        },
        isDesktop: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            tempShopName: '',
            showChatTooltip: false,
        };
    },
    computed: {
        ...mapGetters(['isMasquerade', 'isMitchell1User', 'userHasAnyRole', 'userCanAccessSupport']),
        ...mapState({
            user: ({ user }) => user,
            shops: ({ shops }) => shops,
            currentShop: ({ currentShop }) => currentShop,
            accountName: (state) => state.company?.accountName,
            requests: ({ requests }) => requests,
            csrfObject: ({ csrfObject }) => csrfObject,
            isSPA: ({ isSPA }) => isSPA,
        }),
        currentStoreId() {
            return this.currentShop?.homeStore.storeId;
        },
        isVertical() {
            return this.direction === 'vertical';
        },
        isHorizontal() {
            return this.direction === 'horizontal';
        },
        loggedInUserText() {
            if (this.isMitchell1User) {
                return this.fcoM('rs.homeMain.admin', 'Admin');
            }
            return this.user.loginName;
        },
        shopSearchVal() {
            return this.tempShopName !== '' ? null : this.currentShop?.id;
        },
    },
    methods: {
        handleLogoutClick(event) {
            if (!this.isSPA) this.$fcoLoading('Logging out...');
            else event.preventDefault();

            deleteTableState();
            this.$store.dispatch('logout');
        },
        async handleUnmasqueradeClick() {
            const loading = this.$fcoLoading();
            try {
                await this.$store.dispatch('stopMasquerade');
            } catch (error) {
                this.$fcoToast.error(this.fcoM('masquerade.unmasquerade.failed', 'There was a problem trying to unmasquerade. Please try again.'));
            }
            loading.remove();
        },
        async handleShopSelect({ id, shopName }) {
            if (id === this.currentShop?.id) {
                return;
            }

            const loading = this.$fcoLoading(stringFormat(this.fcoM('rs.pref.loadingChangeShop', 'Changing shop to {0}...'), shopName));

            try {
                await this.$store.dispatch('selectShop', id);
                const canManageAccount = this.userHasAnyRole(Role.SUPERUSER, Role.SYSADMIN, Role.INSTALLER_SUPPORT);
                analytics({
                    event: 'currentStore',
                    data: {
                        currentStoreId: this.currentStoreId,
                    },
                    callback: () => {
                        if (!this.isSPA) {
                            window.location.href = canManageAccount ? this.fcoUrl('/company/home.html') : this.fcoUrl('/');
                            return;
                        }

                        const redirectRoute = canManageAccount ? '/admin/company' : '/';
                        if (this.$route.path !== redirectRoute) {
                            this.$router.push(redirectRoute);
                        }

                        loading.remove();
                    },
                });
            } catch (error) {
                this.$fcoToast.error(this.fcoM('rs.pref.errorChangeShop', 'Error changing the shop, please try again later'));
                loading.remove();
            }
        },
        handleFocus() {
            this.tempShopName = this.currentShop?.shopName;
        },
        handleBlur() {
            this.tempShopName = '';
        },
    },
    async created() {
        await this.$store.dispatch('requestIfIdle', ['getAppData']);
        this.$store.dispatch('requestIfIdle', ['getUser', 'getUserShops', 'getCurrentShop', 'getCompany']);

        // If isDesktop = true, admin bar is showing
        if (this.isDesktop) {
            axios.post(this.fcoUrl('/user/showChatNowTooltip')).then(({ data }) => {
                this.showChatTooltip = data;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';
@import '~scssVariables/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.admin-bar {
    background: $black;
    color: $white;
    border-bottom: 2px solid $white;
    padding: 0 map-get($spacers, 3);

    &.admin-bar-horizontal {
        font-size: 0.8125rem;
    }
}

.admin-bar-container {
    @include container-wide;
}

.admin-bar-account-name {
    white-space: nowrap;
    display: inline-block;
    border-right: 2px solid $gray-600;
}

.admin-bar-shop-name {
    padding-left: map-get($spacers, 2);

    .admin-bar-vertical & {
        display: block;
        padding: map-get($spacers, 2) map-get($spacers, 3);
    }
}

.shop-select {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1792 1792' fill='%2386868a' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath \
    d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z'/%3E%3C/svg%3E%0A");
    background-size: 1em 1em;

    .admin-bar-horizontal & {
        font-size: 0.875rem;
        border-color: transparent;
        padding-left: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        line-height: 1;
        min-height: 0;
        width: 20%;
    }

    &:not(.focused) {
        &,
        ::v-deep .shop-select-input {
            cursor: pointer;
        }
    }
}

.admin-bar::v-deep {
    .shop-select-input {
        font-size: 0.75rem;
        border-color: transparent;
        background: none transparent;
    }

    .fco-dropdown-menu-inner {
        background: $black;
    }

    .search-results {
        max-height: 300px;
    }

    .search-result {
        line-height: 1.5;
        font-size: 0.875rem;
        padding: 0.25rem 0.5rem;
        text-decoration: none;

        &.active {
            background: $yellow;
        }

        &:not(.active) {
            color: $white;
        }
    }

    .header-language-select-button {
        background: none transparent;
        border: 0 none;
        box-shadow: none;
        padding: 0.5rem 1rem;
        color: $white;
        font-weight: normal;

        &:hover,
        &:active,
        &.active,
        &:focus-visible {
            color: $yellow;
        }
    }
}

.admin-bar-nav {
    justify-self: flex-end;

    .nav-item {
        margin-left: 0;
    }

    .nav-link {
        text-decoration: none;

        &,
        &.nav-link-masquerade:hover,
        &.nav-link-masquerade:focus {
            color: inherit;
        }

        &:hover,
        &:focus,
        &.nav-link-masquerade {
            color: $yellow;
        }
    }

    .chat-tooltip {
        position: absolute;
        top: 135%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 15;
        color: $black;
        background: $white;
        width: 175px;

        .close-x {
            cursor: pointer;
            position: relative;
            left: 10px;
            font-size: 14px;
            font-family: verdana, serif;
        }

        &::before {
            content: '';
            position: absolute;
            top: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-bottom: 16px solid $white;
        }
    }
}
</style>
