var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "overflow-auto",
    },
    [
      _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-content-center mt-3",
            },
            [_c("FcoSpinner")],
            1
          )
        : _c(
            "div",
            [
              _c(
                "FcoTable",
                {
                  attrs: {
                    tableClass: {
                      selectable: _vm.hasVehicles,
                    },
                    tbodyClass: {
                      "font-italic": !_vm.hasVehicles,
                    },
                    data: _vm.recentVehicles,
                    perPage: _vm.recentVehicles.length + 1,
                    noResultsText: _vm.fcoM(
                      "rs.homeMain.noVehicles",
                      "You have no recent vehicles."
                    ),
                  },
                  on: {
                    "click-row": function ($event) {
                      return _vm.handleVehicleClick($event.id)
                    },
                  },
                },
                [
                  _vm.$fcoMq.isSmUp
                    ? _c("FcoTableColumn", {
                        attrs: {
                          prop: "createDateTime",
                          label: _vm.fcoM("common.date", "Date"),
                          sortable: false,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$fcoMq.isMdUp
                    ? _c("FcoTableColumn", {
                        staticClass: "text-nowrap",
                        attrs: {
                          label: _vm.fcoM("common.vin", "VIN"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "td",
                              fn: function (recentVehicle) {
                                return [
                                  recentVehicle.vin
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "text-uppercase",
                                        },
                                        [_vm._v(_vm._s(recentVehicle.vin))]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.fcoM("common.na", "N/A"))
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  recentVehicle.sourceVinScanner
                                    ? [
                                        _c("Icon", {
                                          staticClass: "ml-1 text-lg",
                                          attrs: {
                                            name: "mobile",
                                          },
                                        }),
                                        _vm._v(" "),
                                        recentVehicle.vehicleNotes &&
                                        recentVehicle.vehicleNotes !== ""
                                          ? _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "fco-tooltip",
                                                    rawName: "v-fco-tooltip",
                                                    value: {
                                                      content:
                                                        recentVehicle.vehicleNotes,
                                                      container: _vm.$el,
                                                    },
                                                    expression:
                                                      "{ content: recentVehicle.vehicleNotes, container: $el }",
                                                  },
                                                ],
                                              },
                                              [
                                                _c("Icon", {
                                                  staticClass:
                                                    "ml-1 clickable text-lg",
                                                  attrs: {
                                                    name: "pencil-square-o",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          854423622
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$fcoMq.isMdUp
                    ? _c("FcoTableColumn", {
                        attrs: {
                          label: _vm.fcoM(
                            "common.vehicleLicensePlate",
                            "License Plate #"
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "td",
                              fn: function (recentVehicle) {
                                return [
                                  recentVehicle.licensePlate !== ""
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(recentVehicle.licensePlate)
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.fcoM("common.na", "N/A"))
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2168156665
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("FcoTableColumn", {
                    staticClass: "text-nowrap",
                    attrs: {
                      label: _vm.fcoM("common.vehicle", "Vehicle"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "td",
                        fn: function (recentVehicle) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(recentVehicle.epcVehicleYear) +
                                " " +
                                _vm._s(recentVehicle.epcVehicleMake) +
                                " " +
                                _vm._s(recentVehicle.epcVehicleModel) +
                                "\n                "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("FcoTableColumn", {
                    attrs: {
                      tdClass: "delete-vehicle center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "td",
                        fn: function (recentVehicle) {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteVehicle(recentVehicle.id)
                                  },
                                },
                              },
                              [
                                _c("Icon", {
                                  staticClass: "clickable red",
                                  attrs: {
                                    name: "times",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showNewQuotePrompt
        ? _c("QuoteSelectionModal", {
            on: {
              close: function ($event) {
                _vm.showNewQuotePrompt = false
              },
              "start-new-quote": function ($event) {
                return _vm.handleQuotePromptAnswer(false)
              },
              "use-existing-quote": function ($event) {
                return _vm.handleQuotePromptAnswer(true)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }